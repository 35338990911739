import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import styles from "../../../../projectEstate/Project.module.scss";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import { FilterLiveSearch, useTranslate } from "react-admin";
import {
  Pagination,
  List,
  Datagrid,
  DatagridProps,
  useListContext,
  TextField,
  FunctionField,
  useRefresh,
  useNotify,
} from "react-admin";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Typography,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormControl,
  Tooltip,
} from "@mui/material";
import HttpDataService from "../../../../dataProvider/http.service";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteTransaction from "../DeleteTransaction";
import ViewDetailTransaction from "../ViewDetailTransaction";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "right",
}));
import { commonFunction } from "../../../../component/Common";
import EditTransaction from "../EditTransaction";

export const TransactionList = (props: any) => {
  //   const PostPagination = (props: any) => <PaginationCustom {...props} />;
  const translate = useTranslate();

  return (
    <Box className="header-channel-sales">
      <Box className={"box_header"}>
        <Typography className={"title_header"}>
          {"Danh sách giao dịch"}
        </Typography>
      </Box>
      <div className={`header-top-detail header-top-tdn`}>
        <List
          {...props}
          actions={false}
          empty={false}
          exporter={false}
          sort={{ field: "", order: "" }}
          perPage={50}
        >
          <DepartmentDatagrid {...props} />
        </List>
      </div>
    </Box>
  );
};

interface ButtonProps {
  basePath?: string;
  label: string;
  record?: any;
}

// const FilterAndActions = (props: any) => {
//   const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);

//   return (
//     <Box
//       sx={{
//         p: 1,
//         m: 1,
//         bgcolor: "background.paper",
//         borderRadius: 1,
//       }}
//       className={"box_search"}
//     >
//       <Item className="shadow-none">
//         <Form onSubmit={() => undefined}>
//           {() => (
//             <FilterLiveSearch
//               variant="outlined"
//               source="name"
//               label="resources.common.fields.search"
//             />
//           )}
//         </Form>
//       </Item>
//       {commonFunction.checkPermission("CUSTOMER_SOURCE_ADD") && (
//         <Item className="shadow-none">
//           <Button
//             variant="outlined"
//             startIcon={<AddIcon />}
//             color="primary"
//             onClick={() => setOpenCreateDialog(true)}
//           >
//             Thêm mới
//           </Button>
//           <CustomerSourcesAdd
//             openDialog={openCreateDialog}
//             onCloseDialog={() => setOpenCreateDialog(false)}
//           />
//         </Item>
//       )}
//     </Box>
//   );
// };
const DepartmentDatagrid = (props: DatagridProps) => {
  // @ts-ignore
  const refresh = useRefresh();
  const notify = useNotify();
  const handleChangeCustomStatus = async (
    event: SelectChangeEvent,
    idRecord: Number
  ) => {
    let data = {
      status: event.target.value,
    };

    await HttpDataService.update(
      `transaction/change-status-transaction/${idRecord}`,
      data
    )
      .then((response: any) => {
        console.log(response);
        if (response.data.status) {
          // @ts-ignore
          notify(response.data.message, { type: "success" });
        } else {
          // @ts-ignore
          notify(response.data.message, { type: "error" });
        }
      })
      .catch((e: Error) => {
        // @ts-ignore
        notify("Cập nhật không thành công", { type: "error" });
      });
    refresh();
  };
  const RowActions = ({ record }: ButtonProps) => {
    return (
      <Box className={cx("planRowAction")}>
        <ViewButton />
        {/* <EditButton /> */}
        {commonFunction.checkPermission("TRANSACTION_DELETE") && (
          <DeleteButton />
        )}
      </Box>
    );
  };
  const EditButton = (props: any) => {
    const [openEditDialog, setOpenEditDialog] = useState(false);
    return (
      <>
        <Tooltip title="Cập nhật giao dịch" arrow>
          <IconButton onClick={() => setOpenEditDialog(true)}>
            <EditIcon className={"btn_replay"} />
          </IconButton>
        </Tooltip>
        <EditTransaction
          isOpen={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
        ></EditTransaction>
      </>
    );
  };
  const ViewButton = (props: any) => {
    const [openEditDialog, setOpenEditDialog] = useState(false);
    return (
      <>
        <Tooltip title="Xem giao dịch" arrow>
          <IconButton onClick={() => setOpenEditDialog(true)}>
            <VisibilityIcon className={"btn_replay"} />
          </IconButton>
        </Tooltip>
        <ViewDetailTransaction
          isOpen={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
        ></ViewDetailTransaction>
      </>
    );
  };

  const DeleteButton = (props: any) => {
    const [openEditDialog, setOpenEditDialog] = useState(false);
    return (
      <>
        <Tooltip title="Xóa" arrow>
          <IconButton onClick={() => setOpenEditDialog(true)}>
            <DeleteIcon className={"btn_del"} />
          </IconButton>
        </Tooltip>
        <DeleteTransaction
          isOpen={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
        ></DeleteTransaction>
      </>
    );
  };

  return (
    <div className={`list-data-tdn`}>
      <div className={`list-data-tdn`}>
        <Datagrid optimized bulkActionButtons={false}>
          <TextField source={"project_estate_code"} label={"Mã dự án"} />
          <TextField source={"customer_code"} label={"Mã khách hàng"} />
          <TextField source={"source"} label={"Nguồn"} />
          <TextField source={"user_sale"} label={"Người sale"} />
          <TextField source={"created_name"} label={"Người tạo"} />
          <TextField source={"user_project"} label={"Nguồn dự án"} />
          <FunctionField
            label={"Doanh thu"}
            render={(record: any) => {
              return record?.revenue
                ? record?.revenue.toLocaleString("vi", {
                    style: "currency",
                    currency: "VND",
                  })
                : "";
            }}
          />
          {/* <FunctionField
						label={"Doanh số nguồn"}
						render={(record: any) => {
							return record?.source
								? record?.source.toLocaleString("vi", {
										style: "currency",
										currency: "VND",
								  })
								: "";
						}}
					/> */}
          <FunctionField
            label={"Doanh số sale"}
            render={(record: any) => {
              return record?.sale
                ? record?.sale.toLocaleString("vi", {
                    style: "currency",
                    currency: "VND",
                  })
                : "";
            }}
          />
          <FunctionField
            label={"Doanh số nguồn"}
            render={(record: any) => {
              return record?.source_cost
                ? record?.source_cost.toLocaleString("vi", {
                    style: "currency",
                    currency: "VND",
                  })
                : "";
            }}
          />
          <FunctionField
            label={"Giá thuê"}
            render={(record: any) => {
              return record?.rent_cost
                ? record?.rent_cost.toLocaleString("vi", {
                    style: "currency",
                    currency: "VND",
                  })
                : "Đang cập nhật";
            }}
          />
          <FunctionField
            label={"Thời gian"}
            render={(record: any) => {
              return moment(record?.time).format("DD/MM/YYYY");
            }}
          />
          {commonFunction.checkPermission("TRANSACTION_CHANGE_STATUS") ? (
            <FunctionField
              label={"Trạng thái"}
              className={cx("status")}
              render={(record: any) => {
                return (
                  <FormControlLabel
                    control={
                      <FormControl variant="standard">
                        <Select
                          sx={{
                            ...(record.status === 0
                              ? { color: "red" }
                              : { color: "green" }),
                          }}
                          value={record?.status}
                          onChange={(e) =>
                            handleChangeCustomStatus(e, record.id)
                          }
                        >
                          <MenuItem style={{ color: "red" }} value={0}>
                            Chưa giao dịch
                          </MenuItem>
                          <MenuItem style={{ color: "green" }} value={1}>
                            Đã giao dịch
                          </MenuItem>
                        </Select>
                      </FormControl>
                    }
                    label=""
                    labelPlacement="end"
                  />
                );
              }}
            />
          ) : (
            <FunctionField
              label={"Trạng thái"}
              className={cx("status")}
              render={(record: any) => {
                if (record.status == 1) {
                  return (
                    <Chip
                      label="Đã giao dịch"
                      color="success"
                      variant="outlined"
                    />
                  );
                } else {
                  return (
                    <Chip
                      label="Chưa giao dịch"
                      color="error"
                      variant="outlined"
                    />
                  );
                }
              }}
            />
          )}
          <TextField source={"feedback_customer"} label={"Feedback KH"} />
          <TextField source={"feedback_building"} label={"Feedback tòa nhà"} />
          <FunctionField
            className={cx("status")}
            label={"THAO TÁC"}
            render={(record: any) => {
              return <RowActions label={"THAO TÁC"} record={record} />;
            }}
          />
        </Datagrid>
      </div>
    </div>
  );
};
