import { Autocomplete, Box, Button, Container, IconButton, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import { styled } from "@mui/system";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InputLabel from "../../../component/InputLabel";
import { useEffect, useState } from "react";
import { STATUS_LEASE } from "../../../shared/status";
import PriceInput from "../../../component/PriceInput";
import { ResponsiveItemWrapper, ResponsiveItemWrapper30 } from "../../dialogs/styledMui";
import httpService from "../../../dataProvider/http.service";
import * as request from "../../../utils/httpRequest";
import { commonFunction } from "../../../component/Common";

type Props = {
	onDataFloor: (value: any) => void;
	val?: any;
	readonlyPrice?: boolean | false;
	isDollar?: boolean;
	submitLoading?: boolean;
};

const HeaderButtonWrapper = styled(Box)({
	display: "flex",
	justifyContent: "flex-end",
	gap: 10,
	padding: "0 10px",
});

const Wrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	backgroundColor: "#fff",
});

const InputRowWrapper = styled(Box)({
	display: "flex",
	width: "100%",
	flexWrap: "wrap",
});

const BoxWrapper = styled(Box)({
	position: "relative",
});

const IconRemove = styled("div")({
	position: "absolute",
	right: "-25px",
	top: "20px",
});

const styles = makeStyles((theme: any) =>
	createStyles({
		root: {
			"& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
				position: "absolute",
				paddingTop: "41px",
			},
			"& .css-1z10yd4-MuiFormControl-root-MuiTextField-root": {
				marginTop: 20,
				marginBottom: 20,
			},
		},
	})
);

function BuildingFloor({ onDataFloor, val, readonlyPrice, isDollar, submitLoading }: Props) {
	const classes = styles();
	const [listFloor, setListFloor] = useState<any[]>([]);

	// useEffect(() => {
	//     if(price) {
	//         setListFloor((prevList) =>
	//             prevList.map((item) => {
	//                 return { ...item, price: item.area*price };
	//             })
	//         );
	//     }
	// }, [price])

	useEffect(() => {
		if (val) {
			const updatedData = val.map((item: any) => {
				return { ...item, status: STATUS_LEASE.find((x) => x.id === item.status) };
			});
			setListFloor(updatedData);
			onDataFloor(updatedData);
		}
	}, []);

	const handleClickAdd = () => {
		setListFloor((preValue) => [
			...preValue,
			{
				id: listFloor.length + 1,
				name: "",
				area: "",
				unit_price: "",
				service_price: "",
				price: "",
				service: "",
				status: STATUS_LEASE[0],
			},
		]);
	};

	// const handleRemoveItem = (id: Number) => {
	//     setListFloor(listFloor.filter(i => i.id != id))}
	// }
	const handleRemoveItem = (itemId: number) => {
		const listRemove = listFloor.filter((i) => i.id != itemId);
		const updatedData = listRemove.map((item, index) => {
			return { ...item, id: index };
		});

		setListFloor(updatedData);
		onDataFloor(updatedData);
	};

	const handleChange = (e: any, fieldName: string, itemId: number) => {
		const updatedData = listFloor.map((item) => {
			if (item.id === itemId) {
				const updatedItem: any = { ...item, [fieldName]: e };
				console.log(updatedItem);
				// Check if calPrice is not empty before setting
				if (readonlyPrice) {
					let price = updatedItem.unit_price;
					let servicePrice = updatedItem.service_price;

					if (isDollar) {
						price = Math.round(updatedItem.unit_price * (commonFunction.getDollarRate() || 24000));
						servicePrice = Math.round(
							updatedItem.service_price * (commonFunction.getDollarRate() || 24000)
						);
					}

					updatedItem.price = price * updatedItem.area;
					updatedItem.service = servicePrice * updatedItem.area;
				}

				return updatedItem;
			}
			return item;
		});

		setListFloor(updatedData);
		onDataFloor(updatedData);
	};

	const handleChangeSelect = (e: any, value: any, fieldName: string, itemId: number) => {
		const updatedData = listFloor.map((item) => {
			if (item.id === itemId) {
				return { ...item, [fieldName]: value };
			}
			return item;
		});
		setListFloor(updatedData);
		onDataFloor(updatedData);
	};

	// const changeNumberArea = (data: any, fieldName: string) => {
	//     console.log(data)
	//     console.log(fieldName)
	//     // setListFloor(updatedData);
	//     // onDataFloor(updatedData);
	// }

	return (
		<div className="main-image-project">
			<InputRowWrapper>
				<div className={"title-image"}>Thông tin các tầng:</div>
				<HeaderButtonWrapper>
					<Button
						disabled={submitLoading}
						startIcon={<AddIcon />}
						onClick={handleClickAdd}
						color="primary"
						variant="outlined"
					>
						Thêm tầng
					</Button>
				</HeaderButtonWrapper>
			</InputRowWrapper>
			<Wrapper style={{ height: listFloor.length > 1 ? '320px' : 'auto' }}>
				<Container style={{ overflow: 'hidden auto' }}>
					{listFloor?.map((item: any, index: number) => (
						<BoxWrapper>
							<InputRowWrapper>
								<ResponsiveItemWrapper>
									<TextField
										className={classes.root}
										fullWidth
										id="outlined-basic"
										label={<InputLabel title="Tên tầng" />}
										variant="outlined"
										value={item.name}
										onChange={(e) => handleChange(e.target.value, "name", item.id)}
										inputProps={{ readOnly: submitLoading }}
									/>
								</ResponsiveItemWrapper>
								<ResponsiveItemWrapper>
									<PriceInput
										title="Diện tích"
										val={item.area}
										onChange={(value) => handleChange(value, "area", item.id)}
										required={false}
										// submitLoading={submitLoading}
										readonly={submitLoading}
									/>
								</ResponsiveItemWrapper>
								<ResponsiveItemWrapper>
									<TextField
										className={classes.root}
										fullWidth
										id="outlined-basic"
										label={<InputLabel title="Đơn giá cho thuê" />}
										variant="outlined"
										value={item.unit_price}
										onChange={(e) => handleChange(e.target.value, "unit_price", item.id)}
										// InputProps={{
										// 	readOnly: !readonlyPrice,
										// }}
										inputProps={{ readOnly: submitLoading }}
									/>
								</ResponsiveItemWrapper>

								<ResponsiveItemWrapper>
									<TextField
										className={classes.root}
										fullWidth
										id="outlined-basic"
										label={<InputLabel title="Đơn giá phí dịch vụ" />}
										variant="outlined"
										value={item.service_price}
										onChange={(e) => handleChange(e.target.value, "service_price", item.id)}
										// InputProps={{
										// 	readOnly: !readonlyPrice,
										// }}
										inputProps={{ readOnly: submitLoading }}
									/>
								</ResponsiveItemWrapper>
								<IconRemove>
									<IconButton
										aria-label="delete"
										color={"error"}
										onClick={() => handleRemoveItem(item.id)}
										disabled={submitLoading}
									>
										<CloseIcon />
									</IconButton>
								</IconRemove>
							</InputRowWrapper>
							<InputRowWrapper>
								<ResponsiveItemWrapper>
									<PriceInput
										title="Giá cho thuê"
										val={item.price}
										onChange={(value) => handleChange(value, "price", item.id)}
										required={false}
										readonly={submitLoading}
										// submitLoading={submitLoading}
									/>
								</ResponsiveItemWrapper>
								<ResponsiveItemWrapper>
									<PriceInput
										title="Phí dịch vụ"
										val={item.service?.toString()}
										onChange={(value) => handleChange(value, "service", item.id)}
										required={false}
										readonly={submitLoading}
										// submitLoading={submitLoading}
									/>
								</ResponsiveItemWrapper>
								<ResponsiveItemWrapper>
									<Autocomplete
										fullWidth
										id="combo-box-demo"
										options={STATUS_LEASE}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label="Trạng thái"
											/>
										)}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										value={item.status}
										getOptionLabel={(option) => option?.label}
										onChange={(e, value) => handleChangeSelect(e, value, "status", item.id)}
										// disabled={submitLoading}
										readOnly={submitLoading}
									/>
								</ResponsiveItemWrapper>
							</InputRowWrapper>
						</BoxWrapper>
					))}
				</Container>
			</Wrapper>
		</div>
	);
}
export default BuildingFloor;
