import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Toolbar from "@mui/material/Toolbar";
import {
  useNotify,
  useRefresh,
  useRecordContext,
  EditProps,
  useTranslate,
} from "react-admin";
import HttpDataService from "../dataProvider/http.service";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as projectTypeService from "../Services/projectTypeService";
import * as projectTypeEstateService from "../Services/projectTypeEstateService";

const styles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        paddingTop: "41px",
      },
    },
  })
);

interface Add extends EditProps {
  openDialog: boolean;
  onCloseDialog: (openDialog: boolean) => void;
}

export const SourceEdit = ({ openDialog, onCloseDialog, ...rest }: Add) => {
  const classes = styles();
  const [name, setName] = useState<string>("");
  const translate = useTranslate();
  const dataStatus = [
    { label: translate("resources.common.fields.active"), id: 1 },
    { label: translate("resources.common.fields.block"), id: 0 },
  ];

  const [nameError, setNameError] = useState(false);
  const [textNameError, setTextNameError] = useState("");
  const [status, setStatus] = useState<any>(dataStatus[0]);
  const [phone, setPhone] = useState<string>("");
  const [unit, setUnit] = useState<string>("");
  const [contact, setContact] = useState<string>("");

  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext();

  useEffect(() => {
    HttpDataService.findById("source/getSource", record.id as string)
      .then((response: any) => {
        if (response.data.status) {
          const res_record = response.data.data;
          setName(res_record.name);
          setPhone(res_record.phone);
          setUnit(res_record.unit);
          setContact(res_record.contact);
          const find_status = dataStatus.find((s) => s.id == res_record.status);
          setStatus(find_status);
        } else {
          // @ts-ignore
          notify("Lỗi", { type: "error" });
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }, [record.id]);

  /**
   * handle change name
   * @param e
   */
  const handleNameChange = (e: any) => {
    setNameError(false);
    setTextNameError("");
    setName(e.target.value);
  };

  const handleChangeStatus = (e: any) => {
    setStatus(e);
  };

  /**
   * handle save
   */
  const handleSave = async () => {
    if (!fnValidation()) {
      let data = {
        id: record.id,
        name: name.trim(),
        phone,
        unit,
        contact,
        status: status.id,
      };
      await HttpDataService.update("source/edit", data)
        .then((response: any) => {
          if (response.data.status) {
            // @ts-ignore
            notify(response.data.message, { type: "success" });

            fnResetFrm();
            onCloseDialog(false);
            refresh();
          } else {
            // @ts-ignore
            notify(response.data.message, { type: "error" });
          }
        })
        .catch((e: any) => {
          console.log(e);
          setNameError(true);
          setTextNameError(e?.response.data.errors.name[0]);
        });
    }
  };

  const handleCloseDialog = () => {
    fnResetFrm();
    onCloseDialog(false);
  };

  /**
   * fn validation form
   */
  function fnValidation() {
    let error = false;
    if (name.trim().length === 0) {
      error = true;
      setNameError(true);
      setTextNameError("Tên nguồn không được để trống");
    }

    return error;
  }

  function fnResetFrm() {
    setName("");
    setNameError(false);
    setStatus(null);
    setPhone("");
    setUnit("");
    setContact("");
  }

  return (
    <>
      <Dialog
        open={openDialog}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 2,
            // minWidth: '85%',
          },
        }}
      >
        <div className={"common__dialogTitle_area"}>
          <DialogTitle className={"member__dialogTitle"}>
            <div>{"Cập nhật nguồn"}</div>
          </DialogTitle>
          <div className={"common__dialogTitle_actions"}>
            <IconButton
              aria-label="exists"
              color={"error"}
              onClick={() => {
                onCloseDialog(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Box className={"dialog_box"}>
          <DialogContent className={"dialog_content"}>
            <TextField
              className={`${classes.root} mb-23`}
              fullWidth
              label={
                <span className={"span_style"}>
                  {"Tên nguồn"}
                  <span style={{ color: "red" }}>(*)</span>
                </span>
              }
              value={name}
              error={nameError}
              helperText={textNameError}
              onChange={handleNameChange}
              variant="outlined"
            />
            <TextField
              className={`${classes.root} mb-23`}
              fullWidth
              label={<span className={"span_style"}>{"Số điện thoại"}</span>}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              variant="outlined"
            />

            <TextField
              className={`${classes.root} mb-23`}
              fullWidth
              label={<span className={"span_style"}>{"Đơn vị"}</span>}
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
              variant="outlined"
            />

            <TextField
              className={`${classes.root} mb-23`}
              fullWidth
              label={<span className={"span_style"}>{"Người liên hệ"}</span>}
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              variant="outlined"
            />
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={
                    <span className={"span_style"}>
                      {" "}
                      {translate("resources.common.fields.status")}
                    </span>
                  }
                />
              )}
              fullWidth
              value={status || null}
              onChange={(event, value) => handleChangeStatus(value)}
              options={dataStatus}
            />
          </DialogContent>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="center" width={"100%"}>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              {translate("resources.common.fields.update")}
            </Button>
            &nbsp;&nbsp;
            <Button
              startIcon={<ReplyIcon />}
              className={"button-close"}
              variant="contained"
              onClick={handleCloseDialog}
            >
              {translate("resources.common.fields.cancel")}
            </Button>
          </Box>
        </Toolbar>
      </Dialog>
    </>
  );
};
