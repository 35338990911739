import React, { useRef } from "react";
import {
  Wrapper,
  InputRowWrapper,
  ItemWrapper,
  EditorWrapper,
  ResponsiveItemWrapper,
  ResponsiveItemWrapper30,
} from "../styledMui";
import {
  TextField,
  Autocomplete,
  Container,
  Grid,
  Button,
} from "@mui/material";

// call api
import * as request from "../../../utils/httpRequest";

//Library
import { useNotify, useRefresh } from "react-admin";

//Services
import * as projectTypeService from "../../../Services/projectTypeService";
import * as projectTypeEstateService from "../../../Services/projectTypeEstateService";
import * as cityService from "../../../Services/cityService";
import * as directionService from "../../../Services/directionService";

//Components
import InputLabel from "../../../component/InputLabel";
import PageLoadingAPI from "../../../component/PageLoadingAPI";
import PageSubmitLoading from "../../../component/PageSubmitLoading";
import FullScreenDialogWrapper from "../../../component/FullScreenDialogWrapper";

//Shared data
import { STATUS_PROJECT } from "../../../shared/status";
import { TYPE_PRICE } from "../../../shared/status";
import Editor from "../../../component/Editor";

//Reformat Style
import { createStyles, makeStyles } from "@material-ui/core/styles";

// Switch  component
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

// image
import ImagePreview from "../../../component/ImgPreview";
import classnames from "classnames/bind";
import styleImage from "../../../users/User.module.scss";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { constants } from "../../../shared/constants";
import FreeSoloCreateOptionDialog from "../../../component/FreeSoloCreateOptionDialog";
import BuildingFloor from "../../components/BuildingFloor";
import LimitTags from "../../components/LimitTags";
import PriceInput from "../../../component/PriceInput";
import AutocompleteParent from "../../../component/AutocompleteParent";
import CkeditorServer from "../../../component/CkeditorServer";
import SourceInfo from "../../components/SourceInfo";
import { ISourceInfo } from "../../../types";

const user = JSON.parse(
  localStorage.getItem(constants.LOCAL_STORAGE.USER_IDENTITY) as string
);

const styles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        paddingTop: "41px",
      },
      "& .css-1z10yd4-MuiFormControl-root-MuiTextField-root": {
        marginTop: 20,
        marginBottom: 20,
      },
    },
  })
);

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
type ErrorTypes = {
  isError: boolean;
  message: String;
};

type Product = {
  name: String;
  area: String;
  maps: String;
  project_type_estate: any;
  type: any;
  content: any;
  status: any;
  price: String;
  mainPrice: String;
  city: any;
  district: any;
  street: any;
  ward: any;
  source_id: any;
  direction_id: any;
  meta_title: String;
  meta_description: String;
  meta_keyword: String;
  note_admin: any;
  type_price: any;
  videos: String;
  address: String;
  listFloor: any;
  keyWord: any;
};

type ProjectInfo = {
  building_size: String;
  ceiling_height: String;
  harmonic: String;
  elevator: String;
  parking: String;
  service_charge: String;
  vat: String;
  car_charge: String;
  motor_charge: String;
  overtime: String;
  main_door: String;
  balcony_direction: String;
  interior: String;
  number_floors: String;
  facade: String;
  bedroom: String;
  toilet: String;
  juridical: String;
  empty_floor: String;
  electricity_price: String;
  water_price: String;
  source_info?: ISourceInfo;
};

function ProjectCreate({ isOpen, onClose }: Props) {
  const classes = styles();
  //STATE
  const [inittialState, setInittialState] = React.useState<Product>({
    name: "",
    area: "",
    maps: "",
    type: {},
    content: "",
    status: {},
    price: "",
    mainPrice: "",
    project_type_estate: {},
    city: {},
    district: {},
    street: {},
    ward: {},
    source_id: "",
    direction_id: {},
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
    note_admin: "",
    type_price: {},
    videos: "",
    address: "",
    listFloor: [],
    keyWord: [],
  });

  const [inittialStateInfo, setInittialStateInfo] = React.useState<ProjectInfo>(
    {
      building_size: "",
      ceiling_height: "",
      harmonic: "",
      elevator: "",
      parking: "",
      service_charge: "",
      vat: "",
      car_charge: "",
      motor_charge: "",
      overtime: "",
      main_door: "",
      balcony_direction: "",
      interior: "",
      number_floors: "",
      facade: "",
      bedroom: "",
      toilet: "",
      juridical: "",
      empty_floor: "",
      electricity_price: "",
      water_price: "",
    }
  );
  //STATE REST API
  const [listProjectType, setListProjectType] = React.useState<any[]>([]);
  const [listProjectTypeEstate, setListProjectTypeEstate] = React.useState<
    any[]
  >([]);

  const [listCity, setListCity] = React.useState<any[]>([]);
  const [listDistrict, setListDistrict] = React.useState<any[]>([]);
  const [listStreet, setListStreet] = React.useState<any[]>([]);
  const [listWard, setListWard] = React.useState<any[]>([]);
  const [projectTypeEstate, setProjectTypeEstate] = React.useState<any>();
  const [projectType, setProjectType] = React.useState<any>();
  const [choose, setChoose] = React.useState<boolean>(false);
  const [listDirection, setListDirection] = React.useState<any[]>([]);

  const [contentProject, setContentProject] = React.useState<any>();

  //DIALOG

  //LOADING
  const [pageLoading, setPageLoading] = React.useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);

  //ERROR STATE
  const [nameErr, setNameErr] = React.useState<ErrorTypes | null>();
  const [areaErr, setAreaErr] = React.useState<ErrorTypes | null>();
  const [mapsErr, setMapsErr] = React.useState<ErrorTypes | null>();
  const [typeErr, setTypeErr] = React.useState<ErrorTypes | null>();
  const [statusErr, setStatusErr] = React.useState<ErrorTypes | null>();
  const [keywordErr, setKeywordErr] = React.useState<ErrorTypes | null>();
  const [sourceErr, setSourceErr] = React.useState<ErrorTypes | null>();
  const [cityErr, setCityErr] = React.useState<ErrorTypes | null>();
  const [districtErr, setDistrictErr] = React.useState<ErrorTypes | null>();
  const [streetErr, setStreetErr] = React.useState<ErrorTypes | null>();
  const [typeEstateErr, setTypeEstateErr] = React.useState<ErrorTypes | null>();
  const [noteErr, setNoteErr] = React.useState<ErrorTypes | null>();
  const [typePriceErr, setTypePriceErr] = React.useState<ErrorTypes | null>();
  const [videosErr, setVideosErr] = React.useState<ErrorTypes | null>();
  const [mainPriceErr, setMainPriceErr] = React.useState<ErrorTypes | null>();
  const [wardErr, setWardErr] = React.useState<ErrorTypes | null>();
  const [directionErr, setDirectionErr] = React.useState<ErrorTypes | null>();
  const [metaTitleErr, setMetaTitleErr] = React.useState<ErrorTypes | null>();
  const [metaDescErr, setMetaDescErr] = React.useState<ErrorTypes | null>();
  const [metaKeywordErr, setMetaKeywordErr] =
    React.useState<ErrorTypes | null>();

  //HOOKS
  const notify = useNotify();
  const refresh = useRefresh();

  // checker
  const [checked, setChecked] = React.useState(false);
  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  // checker
  const [current_status, setCheckedStatus] = React.useState(false);
  const handleCurrentStatus = (event: any) => {
    setCheckedStatus(event.target.checked);
  };

  // image
  const cx = classnames.bind(styleImage);
  const [image, setImage] = React.useState<any[]>([]);
  const removeImageUtilities = (imgIndex: number) => {
    const newListImage = image.filter((_, index) => index !== imgIndex);
    if (image.length === 1) {
      setImage([]);
    }
    setImage(newListImage);
  };

  //avatar
  const [pst_image, setPstImage] = React.useState<any[]>([]);
  const removePstImageUtilities = (imgIndex: number) => {
    const newListPstImage = pst_image.filter((_, index) => index !== imgIndex);
    if (pst_image.length === 1) {
      setPstImage([]);
    }
    setPstImage(newListPstImage);
  };

  // main detail-project
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  //CALL API
  // React.useEffect(() => {
  //   if (isOpen) {
  //     setPageLoading(true);
  //     resetData();
  //     resetError();
  //     const fetchAPI = async () => {
  //       const listProjectTypeResult =
  //         await projectTypeService.getListProjectType();
  //       setListProjectType(listProjectTypeResult);
  //       setPageLoading(false);
  //     };
  //     fetchAPI();
  //   }
  // }, [isOpen]);

  //api loại hình
  React.useEffect(() => {
    if (isOpen) {
      setPageLoading(true);
      resetData();
      resetError();
      const fetchAPI = async () => {
        const listProjectTypeEstateResult =
          await projectTypeEstateService.getListTypeEstate();
        setListProjectTypeEstate(listProjectTypeEstateResult);
        setPageLoading(false);
      };
      fetchAPI();
    }
  }, [isOpen]);

  // api Tỉnh thành
  React.useEffect(() => {
    if (isOpen) {
      setPageLoading(true);
      resetData();
      resetError();
      const fetchAPI = async () => {
        const listCityResult = await cityService.getListCity();
        setListCity(listCityResult);

        const listDirectionResult = await directionService.getListDirection();
        setListDirection(listDirectionResult);

        const listStreet = await request.get(`streets/get-all-street`);
        setListStreet(listStreet?.data);

        setPageLoading(false);
      };
      fetchAPI();
    }
  }, [isOpen]);

  const removeItem = (itemIndex: number, list: any[], setList: any) => {
    const newList = list.filter((_, index) => index !== itemIndex);
    if (list.length === 1) {
      setList([]);
    }
    setList(newList);
  };

  const resetError = () => {
    setNameErr(null);
    setAreaErr(null);
    setMapsErr(null);
    setTypeErr(null);
    setStatusErr(null);
    setKeywordErr(null);
    setSourceErr(null);
    setCityErr(null);
    setDistrictErr(null);
    setStreetErr(null);
    setTypeEstateErr(null);
    setNoteErr(null);
    setTypePriceErr(null);
    setVideosErr(null);
    setMainPriceErr(null);
    setWardErr(null);
    setDirectionErr(null);
    setMetaTitleErr(null);
    setMetaDescErr(null);
    setMetaKeywordErr(null);
  };

  const resetData = () => {
    setInittialState({
      name: "",
      area: "",
      maps: "",
      project_type_estate: {},
      type: {},
      content: "",
      status: {},
      price: "",
      mainPrice: "",
      city: {},
      district: {},
      ward: {},
      street: {},
      source_id: "",
      direction_id: {},
      meta_title: "",
      meta_description: "",
      meta_keyword: "",
      note_admin: "",
      type_price: {},
      videos: "",
      address: "",
      listFloor: [],
      keyWord: [],
    });

    setInittialStateInfo({
      building_size: "",
      ceiling_height: "",
      harmonic: "",
      elevator: "",
      parking: "",
      service_charge: "",
      vat: "",
      car_charge: "",
      motor_charge: "",
      overtime: "",
      main_door: "",
      balcony_direction: "",
      interior: "",
      number_floors: "",
      facade: "",
      bedroom: "",
      toilet: "",
      juridical: "",
      empty_floor: "",
      electricity_price: "",
      water_price: "",
    });
    setImage([]);
    setPstImage([]);
  };

  const handleSubmit = () => {
    let pass = false;
    resetError();

    if (!inittialState?.name.trim()) {
      pass = true;
      setNameErr({
        isError: true,
        message: "Tên dự án không được để trống!",
      });
    } else if (inittialState?.name.length < 3) {
      pass = true;
      setNameErr({
        isError: true,
        message: "Tên dự án phải chứa ít nhât 3 ký tự!",
      });
    }

    if (!inittialState?.maps.trim()) {
      pass = true;
      setMapsErr({
        isError: true,
        message: "Google maps không được để trống!",
      });
    }
    if (!inittialState?.type.id) {
      pass = true;
      setTypeErr({
        isError: true,
        message: "Loại dự án không được để trống!",
      });
    }
    if (!inittialState?.project_type_estate.id) {
      pass = true;
      setTypeEstateErr({
        isError: true,
        message: "Loại hình không được để trống!",
      });
    }
    if (!inittialState?.status?.label) {
      pass = true;
      setStatusErr({
        isError: true,
        message: "Trạng thái không được để trống!",
      });
    }
    if (inittialState.keyWord?.length <= 0) {
      pass = true;
      setKeywordErr({
        isError: true,
        message: "Từ khóa không được để trống!",
      });
    }

    if (!inittialState?.source_id) {
      pass = true;
      setSourceErr({
        isError: true,
        message: "Nguồn không được để trống!",
      });
    }

    if (!inittialState?.meta_title.trim()) {
      pass = true;
      setMetaTitleErr({
        isError: true,
        message: "Meta title không được để trống!",
      });
    }

    if (!inittialState?.meta_description.trim()) {
      pass = true;
      setMetaDescErr({
        isError: true,
        message: "Meta description không được để trống!",
      });
    }

    if (!inittialState?.meta_keyword.trim()) {
      pass = true;
      setMetaKeywordErr({
        isError: true,
        message: "Meta keyword không được để trống!",
      });
    }

    // console.log('12345')
    // if((inittialState?.type_price?.id==1 || inittialState?.type_price?.id==2) && !inittialState?.mainPrice) {
    //   pass = true;
    //   setMainPriceErr({
    //     isError: true,
    //     message: "Giá bán không được để trống!",
    //   })
    // }

    if (!inittialState?.city?.id) {
      pass = true;
      setCityErr({
        isError: true,
        message: "Tỉnh thành không được để trống!",
      });
    }
    if (!inittialState?.district?.id) {
      pass = true;
      setDistrictErr({
        isError: true,
        message: " Quận/huyện không được để trống!",
      });
    }

    if (!inittialState?.ward?.id) {
      pass = true;
      setWardErr({
        isError: true,
        message: " Xã/Phường không được để trống!",
      });
    }

    if (!inittialState?.street?.id) {
      pass = true;
      setStreetErr({
        isError: true,
        message: "Đường không được để trống!",
      });
    }

    if (!inittialState?.direction_id?.id) {
      pass = true;
      setDirectionErr({
        isError: true,
        message: "Hướng không được để trống!",
      });
    }

    if (!inittialState?.type_price?.id?.toString()) {
      pass = true;
      setTypePriceErr({
        isError: true,
        message: "Đơn vị tiền không được để trống!",
      });
    }
    let dtImg: any[] = [];
    image.map((item) => {
      dtImg = [
        ...dtImg,
        {
          image: item.name,
        },
      ];
    });

    if (pass) return;

    const {
      name,
      content,
      maps,
      area,
      status,
      type,
      price,
      mainPrice,
      city,
      district,
      ward,
      street,
      source_id,
      project_type_estate,
      meta_keyword,
      meta_title,
      meta_description,
      note_admin,
      type_price,
      videos,
      address,
      direction_id,
    } = inittialState;

    const {
      building_size,
      ceiling_height,
      harmonic,
      elevator,
      parking,
      service_charge,
      vat,
      car_charge,
      motor_charge,
      overtime,
      main_door,
      balcony_direction,
      interior,
      number_floors,
      facade,
      bedroom,
      toilet,
      juridical,
      electricity_price,
      water_price,
      empty_floor,
      source_info,
    } = inittialStateInfo;

    const data = {
      name: name,
      maps: maps,
      type: type?.id,
      content: contentProject,
      project_type_estate: project_type_estate?.id,
      status: status.id,
      area: area,
      price: price,
      mainPrice: mainPrice,
      is_hot: checked ? 1 : 0,
      status_current: current_status ? 1 : 0,
      provinces: city?.id,
      districts: district?.id,
      wards: ward?.id,
      street: street?.id,
      source_id: !source_id ? null : source_id,
      direction_id: !direction_id?.id ? null : direction_id?.id,
      meta_title: meta_title,
      meta_description: meta_description,
      meta_keyword: meta_keyword,
      videos: videos,
      address: address,
      note_admin: note_admin,
      type_price: type_price.id,
      building_size: building_size,
      ceiling_height: ceiling_height,
      harmonic: harmonic,
      elevator: elevator,
      parking: parking,
      service_charge: service_charge,
      vat: vat,
      car_charge: car_charge,
      motor_charge: motor_charge,
      overtime: overtime,
      main_door: main_door,
      balcony_direction: balcony_direction,
      interior: interior,
      number_floors: number_floors,
      facade: facade,
      bedroom: bedroom,
      toilet: toilet,
      juridical: juridical,
      empty_floor: empty_floor,
      electricity_price: electricity_price,
      water_price: water_price,
      source_info: JSON.stringify(source_info),
    };

    const submitObj = new FormData();

    //list floor
    if (inittialState?.listFloor?.length > 0) {
      const updatedData = inittialState?.listFloor.map((item: any) => {
        return { ...item, status: item.status.id };
      });
      submitObj.append("listFloor", JSON.stringify(updatedData));
      console.log(inittialState?.listFloor);
    }

    if (inittialState?.keyWord?.length > 0) {
      submitObj.append("keyword", JSON.stringify(inittialState?.keyWord));
    }

    if (image) {
      image.map((item: any, index) => {
        submitObj.append(`project_image[${index}]`, item);
      });
    }

    //avatar
    if (pst_image) {
      pst_image.map((item: any) => {
        submitObj.append("pst_image", item);
      });
    }

    Object.keys(data).map((key) =>
      // @ts-ignore
      submitObj.append(key, data[key])
    );

    const submit = async () => {
      try {
        const result: any = await request.post(
          "project_estate/addProject",
          submitObj
        );

        if (result.status == 200) {
          resetData();
          notify(`Thêm mới thành công`, {
            type: "success",
            undoable: true,
          });
          setTimeout(() => {
            setSubmitLoading(false);
          }, 500);
          onClose();
          refresh();
        } else if (result?.status === 401) {
          setSubmitLoading(false);
          setNameErr({
            isError: true,
            message: result.data.errors.name[0],
          });
          notify(result.data.errors.name[0], {
            type: "error",
            undoable: true,
          });
        } else {
          notify(`Thêm mới thất bại`, {
            type: "error",
            undoable: true,
          });
          setSubmitLoading(false);
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
          setSubmitLoading(false);
          setNameErr({
            isError: true,
            message: error?.response.data.errors.name[0],
          });
        }
      }
    };
    submit();
    // }
  };

  const onChangeFiles = (setData?: any) => {
    // @ts-ignore
    CKFinder.popup({
      chooseFiles: true,
      width: 800,
      height: 600,
      onInit: function (finder: any) {
        finder.on("files:choose", function (evt: any) {
          let file = evt.data.files.first();
          setData((prev: any) => [...prev, file.getUrl()]);
        });
      },
    });
  };

  // casll api huyện , quận
  const hanhdlCity = async (value: any) => {
    setListDistrict([]);

    const response = await request.get(
      `districts/getDistrictByProvice/${value.code}`
    );
    if (response.data) {
      setListDistrict(response.data);
    }
  };

  //call api get xã phường
  const handleDistrict = async (value: any) => {
    setListWard([]);

    const response = await request.get(`wards/getWards/${value.code}`);
    if (response.data) {
      setListWard(response.data);
    }
  };

  const handleProjectTypeEstate = async (value: any) => {
    setListProjectType([]);
    const response = await request.get(
      `project_type/getProjectTypeId/${value.project_type_estate}`
    );
    if (response.data) {
      setListProjectType(response.data);
    }
  };

  const handleChoose = () => {
    if (inittialState.project_type_estate !== null) {
      setChoose(false);
    }
  };

  const onSelectSource = (value: any) => {
    let val = typeof value == "object" ? value.id : value;
    console.log(val);
    setInittialState({ ...inittialState, source_id: val });
    setSourceErr(null);
  };

  const onDataBuildingFloor = (value: any) => {
    setInittialState({ ...inittialState, listFloor: value });
  };

  const onSelectKeyword = (value: any) => {
    let lstResult: any = [];
    if (value.length > 0) {
      value.map((i: any) => {
        if (i.inputValue) {
          lstResult = [...lstResult, i.inputValue];
        } else {
          lstResult = [...lstResult, i.id];
        }
      });
    }
    setInittialState({ ...inittialState, keyWord: lstResult });
    setKeywordErr(null);
  };

  const handleSourceInfoChange = (data: ISourceInfo) => {
    setInittialStateInfo({ ...inittialStateInfo, source_info: data });
  };

  return (
    <FullScreenDialogWrapper
      isOpen={isOpen}
      onClose={onClose}
      title="Thêm dự án"
      onSubmit={handleSubmit}
      isSubmit={true}
    >
      {pageLoading ? (
        <PageLoadingAPI />
      ) : (
        <Wrapper>
          {submitLoading && <PageSubmitLoading />}
          <Container>
            <InputRowWrapper>
              <ResponsiveItemWrapper>
                <TextField
                  className={classes.root}
                  disabled={submitLoading}
                  fullWidth
                  error={nameErr?.isError}
                  helperText={nameErr?.message}
                  id="outlined-basic"
                  label={<InputLabel title="Tên dự án" required />}
                  variant="outlined"
                  value={inittialState.name}
                  onChange={(e) => {
                    setInittialState({
                      ...inittialState,
                      name: e.target.value,
                    });
                    setNameErr(null);
                  }}
                />
              </ResponsiveItemWrapper>
              <ResponsiveItemWrapper>
                <TextField
                  className={classes.root}
                  disabled={submitLoading}
                  fullWidth
                  error={mapsErr?.isError}
                  helperText={mapsErr?.message}
                  id="outlined-basic"
                  label={<InputLabel title="Google maps" required />}
                  variant="outlined"
                  value={inittialState.maps}
                  onChange={(e) => {
                    setInittialState({
                      ...inittialState,
                      maps: e.target.value,
                    });
                    setMapsErr(null);
                  }}
                />
              </ResponsiveItemWrapper>
            </InputRowWrapper>
            <InputRowWrapper>
              <ResponsiveItemWrapper>
                <Autocomplete
                  disabled={submitLoading}
                  fullWidth
                  id="combo-box-demo"
                  options={listProjectTypeEstate}
                  renderInput={(params) => (
                    <TextField
                      className={classes.root}
                      error={typeEstateErr?.isError}
                      helperText={typeEstateErr?.message}
                      value={inittialState.project_type_estate}
                      {...params}
                      variant="outlined"
                      onClick={handleChoose}
                      label={<InputLabel title="Loại hình" required />}
                    />
                  )}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    handleProjectTypeEstate(value);
                    setInittialState({
                      ...inittialState,
                      project_type_estate: value,
                    });
                    setProjectTypeEstate(value);
                    setTypeEstateErr(null);
                  }}
                />
              </ResponsiveItemWrapper>
              <ResponsiveItemWrapper>
                <Autocomplete
                  disabled={choose}
                  fullWidth
                  id="combo-box-demo"
                  options={listProjectType}
                  renderInput={(params) => (
                    <TextField
                      className={classes.root}
                      error={typeErr?.isError}
                      helperText={typeErr?.message}
                      value={inittialState.type}
                      {...params}
                      variant="outlined"
                      label={<InputLabel title="Loại dự án" required />}
                    />
                  )}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => {
                    setProjectType(value);
                    setInittialState({
                      ...inittialState,
                      type: value,
                    });
                    setTypeErr(null);
                  }}
                />
              </ResponsiveItemWrapper>
            </InputRowWrapper>
            <InputRowWrapper>
              <ResponsiveItemWrapper>
                <Autocomplete
                  disabled={submitLoading}
                  fullWidth
                  id="combo-box-demo"
                  options={listCity}
                  renderInput={(params) => (
                    <TextField
                      className={classes.root}
                      error={cityErr?.isError}
                      helperText={cityErr?.message}
                      value={inittialState.city}
                      {...params}
                      variant="outlined"
                      label={<InputLabel title="Tỉnh" required />}
                    />
                  )}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    hanhdlCity(value);
                    setInittialState({
                      ...inittialState,
                      city: value,
                      district: {},
                    });
                    setCityErr(null);
                  }}
                />
              </ResponsiveItemWrapper>
              <ResponsiveItemWrapper>
                <Autocomplete
                  disabled={submitLoading}
                  fullWidth
                  id="combo-box-demo"
                  options={listDistrict}
                  renderInput={(params) => (
                    <TextField
                      className={classes.root}
                      error={districtErr?.isError}
                      helperText={districtErr?.message}
                      {...params}
                      variant="outlined"
                      value={inittialState.district}
                      label={<InputLabel title="Huyện/Quận" required />}
                    />
                  )}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => {
                    handleDistrict(value);
                    setInittialState({
                      ...inittialState,
                      district: value,
                    });
                    setDistrictErr(null);
                  }}
                />
              </ResponsiveItemWrapper>
              <ResponsiveItemWrapper>
                <Autocomplete
                  disabled={submitLoading}
                  fullWidth
                  id="combo-box-demo"
                  options={listWard}
                  renderInput={(params) => (
                    <TextField
                      className={classes.root}
                      error={wardErr?.isError}
                      helperText={wardErr?.message}
                      {...params}
                      variant="outlined"
                      value={inittialState.ward}
                      label={<InputLabel title="Xã/Phường" required />}
                    />
                  )}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => {
                    setInittialState({
                      ...inittialState,
                      ward: value,
                    });
                    setWardErr(null);
                  }}
                />
              </ResponsiveItemWrapper>
              <ResponsiveItemWrapper>
                <Autocomplete
                  disabled={submitLoading}
                  fullWidth
                  id="combo-box-demo"
                  options={listStreet}
                  renderInput={(params) => (
                    <TextField
                      className={classes.root}
                      error={streetErr?.isError}
                      helperText={streetErr?.message}
                      {...params}
                      variant="outlined"
                      value={inittialState.street}
                      label={<InputLabel title="Đường" required />}
                    />
                  )}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => {
                    setInittialState({
                      ...inittialState,
                      street: value,
                    });
                    setStreetErr(null);
                  }}
                />
              </ResponsiveItemWrapper>
            </InputRowWrapper>

            <InputRowWrapper>
              <ResponsiveItemWrapper>
                <Autocomplete
                  disabled={submitLoading}
                  fullWidth
                  id="combo-box-demo"
                  options={TYPE_PRICE}
                  renderInput={(params) => (
                    <TextField
                      className={classes.root}
                      error={typePriceErr?.isError}
                      helperText={typePriceErr?.message}
                      {...params}
                      variant="outlined"
                      value={inittialState.type_price}
                      label={<InputLabel title="Đơn vị" required />}
                    />
                  )}
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => {
                    setInittialState({
                      ...inittialState,
                      type_price: value,
                    });
                    setTypePriceErr(null);
                  }}
                />
              </ResponsiveItemWrapper>
              <ResponsiveItemWrapper>
                <Autocomplete
                  disabled={submitLoading}
                  fullWidth
                  id="combo-box-demo"
                  options={STATUS_PROJECT}
                  renderInput={(params) => (
                    <TextField
                      className={classes.root}
                      error={statusErr?.isError}
                      helperText={statusErr?.message}
                      {...params}
                      variant="outlined"
                      value={inittialState.status}
                      label={<InputLabel title="Trạng thái" required />}
                    />
                  )}
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => {
                    setInittialState({
                      ...inittialState,
                      status: value,
                    });
                    setStatusErr(null);
                  }}
                />
              </ResponsiveItemWrapper>
            </InputRowWrapper>
            <InputRowWrapper>
              <ResponsiveItemWrapper>
                <TextField
                  className={classes.root}
                  disabled={submitLoading}
                  fullWidth
                  id="outlined-basic"
                  label={<InputLabel title="Địa chỉ" />}
                  variant="outlined"
                  value={inittialState.address}
                  onChange={(e) =>
                    setInittialState({
                      ...inittialState,
                      address: e.target.value,
                    })
                  }
                />
              </ResponsiveItemWrapper>
              <ResponsiveItemWrapper>
                <FreeSoloCreateOptionDialog
                  submitLoading={submitLoading}
                  title={"Nguồn"}
                  onSelect={onSelectSource}
                  statusError={sourceErr || null}
                />
              </ResponsiveItemWrapper>
            </InputRowWrapper>

            <BuildingFloor
              onDataFloor={onDataBuildingFloor}
              readonlyPrice={
                inittialState?.type_price.id == 1 ||
                inittialState?.type_price.id == 2
                  ? true
                  : false
              }
              isDollar={inittialState?.type_price.id == 2 ? true : false}
            />

            <InputRowWrapper>
              <ResponsiveItemWrapper30>
                <TextField
                  className={classes.root}
                  disabled={submitLoading}
                  fullWidth
                  error={metaTitleErr?.isError}
                  helperText={
                    metaTitleErr?.message ||
                    `${inittialState.meta_title.length}/70`
                  }
                  id="outlined-basic"
                  label={<InputLabel title="Meta title" required />}
                  variant="outlined"
                  value={inittialState.meta_title}
                  onChange={(e) => {
                    setInittialState({
                      ...inittialState,
                      meta_title: e.target.value,
                    });
                    if (e.target.value.length > 70) {
                      setMetaTitleErr({
                        isError: true,
                        message: `Meta title không được vượt quá ${inittialState.meta_title.length}/70 ký tự`,
                      });
                    } else {
                      setMetaTitleErr(null);
                    }
                  }}
                />
              </ResponsiveItemWrapper30>
              <ResponsiveItemWrapper30>
                <TextField
                  className={classes.root}
                  disabled={submitLoading}
                  fullWidth
                  error={metaDescErr?.isError}
                  helperText={
                    metaDescErr?.message ||
                    `${inittialState.meta_description.length}/170`
                  }
                  id="outlined-basic"
                  label={<InputLabel title="Meta description" required />}
                  variant="outlined"
                  value={inittialState.meta_description}
                  onChange={(e) => {
                    setInittialState({
                      ...inittialState,
                      meta_description: e.target.value,
                    });

                    if (e.target.value.length > 170) {
                      setMetaDescErr({
                        isError: true,
                        message: `Meta description không được vượt quá ${inittialState.meta_description.length}/170 ký tự`,
                      });
                    } else {
                      setMetaDescErr(null);
                    }
                  }}
                />
              </ResponsiveItemWrapper30>
              <ResponsiveItemWrapper30>
                <TextField
                  className={classes.root}
                  disabled={submitLoading}
                  fullWidth
                  error={metaKeywordErr?.isError}
                  helperText={metaKeywordErr?.message}
                  id="outlined-basic"
                  label={<InputLabel title="Meta keyword" required />}
                  variant="outlined"
                  value={inittialState.meta_keyword}
                  onChange={(e) => {
                    setInittialState({
                      ...inittialState,
                      meta_keyword: e.target.value,
                    });
                    setMetaKeywordErr(null);
                  }}
                />
              </ResponsiveItemWrapper30>
            </InputRowWrapper>
            <InputRowWrapper>
              <ResponsiveItemWrapper30>
                <Autocomplete
                  disabled={submitLoading}
                  fullWidth
                  id="combo-box-demo"
                  options={listDirection}
                  renderInput={(params) => (
                    <TextField
                      className={classes.root}
                      error={directionErr?.isError}
                      helperText={directionErr?.message}
                      value={inittialState.direction_id}
                      {...params}
                      variant="outlined"
                      label={<InputLabel title="Hướng" required />}
                    />
                  )}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    setInittialState({
                      ...inittialState,
                      direction_id: value,
                    });
                    setDirectionErr(null);
                  }}
                />
              </ResponsiveItemWrapper30>
              <ResponsiveItemWrapper30>
                <LimitTags
                  submitLoading={submitLoading}
                  title="Từ khóa"
                  onSelect={onSelectKeyword}
                  statusError={keywordErr || null}
                />
              </ResponsiveItemWrapper30>
              <ResponsiveItemWrapper30>
                <TextField
                  className={classes.root}
                  disabled={submitLoading}
                  fullWidth
                  error={videosErr?.isError}
                  helperText={videosErr?.message}
                  id="outlined-basic"
                  label={<InputLabel title="Đường dẫn videos" />}
                  variant="outlined"
                  value={inittialState.videos}
                  onChange={(e) =>
                    setInittialState({
                      ...inittialState,
                      videos: e.target.value,
                    })
                  }
                />
              </ResponsiveItemWrapper30>
            </InputRowWrapper>
            <InputRowWrapper>
              <ResponsiveItemWrapper>
                <FormControlLabel
                  control={<Switch checked={checked} onChange={handleChange} />}
                  label="Dự án nổi bật"
                  labelPlacement="start"
                />
              </ResponsiveItemWrapper>
              {/* <ItemWrapper>
                <FormControlLabel
                  control={
                    <Switch
                      checked={current_status}
                      onChange={handleCurrentStatus}
                    />
                  }
                  label="Đã cho thuê"
                  labelPlacement="start"
                />
              </ItemWrapper> */}
            </InputRowWrapper>

            <Grid>
              <div className={"main-image-project"}>
                <div className={cx("upload-image-wrapper")}>
                  <div className={"title-image"}>Avatar</div>
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e: any) => {
                      setPstImage([...e.target.files]);
                      e.target.value = null;
                    }}
                  ></input>
                  {pst_image.length > 0 && (
                    <div className={cx("image-show-wrapper")}>
                      {pst_image.map((pst_image: any, index: number) => (
                        <ImagePreview
                          key={index}
                          src={URL.createObjectURL(pst_image)}
                          onRemove={() => removePstImageUtilities(index)}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </Grid>

            <Grid>
              <div className={"main-image-project"}>
                <div className={cx("upload-image-wrapper")}>
                  <div className={"title-image"}>Hình ảnh</div>
                  <input
                    type="file"
                    multiple
                    accept=".png, .jpg, .jpeg"
                    onChange={(e: any) => {
                      setImage([...image, ...e.target.files]);
                      e.target.value = null;
                    }}
                  ></input>
                  {image.length > 0 && (
                    <div className={cx("image-show-wrapper")}>
                      <Grid
                        style={{ marginTop: "10px" }}
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        {image.map((image: any, index: number) => (
                          <Grid item xs={3}>
                            <Item>
                              <ImagePreview
                                key={index}
                                src={URL.createObjectURL(image)}
                                onRemove={() => removeImageUtilities(index)}
                              />
                            </Item>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  )}
                </div>
              </div>
            </Grid>

            <EditorWrapper>
              <div className={"title-image"}>Mô tả</div>

              <CkeditorServer
                name="project_content_tektrahomes_add"
                onChangeData={(data) => {
                  setContentProject(data);
                }}
              />
              {/* <Editor
                data={inittialState?.content}
                onChange={(event: any, editor: any) => {
                  const data = editor.getData();
                  setInittialState({
                    ...inittialState,
                    content: data,
                  });
				  console.log('inittialState', inittialState)
                }}
              /> */}
            </EditorWrapper>

            <EditorWrapper>
              <div className={"title-image"}>Note</div>
              <Editor
                data={inittialState?.note_admin}
                onChange={(event: any, editor: any) => {
                  const data = editor.getData();
                  setInittialState({
                    ...inittialState,
                    note_admin: data,
                  });
                }}
              />

              {/* <CkeditorServer
                name="project_note_add"
                onChangeData={(data) => {
                  if (data)
                    setInittialState({
                      ...inittialState,
                      note_admin: data,
                    });
                }}
              /> */}
            </EditorWrapper>
            <div className="main-image-project">
              <div className={"title-image"}>Thông tin mô tả:</div>

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <InputRowWrapper>
                    <ResponsiveItemWrapper>
                      <TextField
                        className={classes.root}
                        disabled={submitLoading}
                        fullWidth
                        id="outlined-basic"
                        label={<InputLabel title="Quy mô tòa nhà" />}
                        variant="outlined"
                        value={inittialStateInfo.building_size}
                        onChange={(e) =>
                          setInittialStateInfo({
                            ...inittialStateInfo,
                            building_size: e.target.value,
                          })
                        }
                      />
                    </ResponsiveItemWrapper>
                    <ResponsiveItemWrapper>
                      <TextField
                        className={classes.root}
                        disabled={submitLoading}
                        fullWidth
                        id="outlined-basic"
                        label={<InputLabel title="Phí gửi ô tô" />}
                        variant="outlined"
                        value={inittialStateInfo.car_charge}
                        onChange={(e) =>
                          setInittialStateInfo({
                            ...inittialStateInfo,
                            car_charge: e.target.value,
                          })
                        }
                      />
                    </ResponsiveItemWrapper>
                    {/* <ItemWrapper>
                            <TextField
                              className={classes.root}
                              disabled={submitLoading}
                              fullWidth
                              id="outlined-basic"
                              label={<InputLabel title="Tầng trống" />}
                              variant="outlined"
                              value={inittialStateInfo.empty_floor}
                              onChange={(e) =>
                                setInittialStateInfo({
                                  ...inittialStateInfo,
                                  empty_floor: e.target.value,
                                })
                              }
                            />
                          </ItemWrapper> */}
                  </InputRowWrapper>
                  <InputRowWrapper>
                    <ResponsiveItemWrapper>
                      <TextField
                        className={classes.root}
                        disabled={submitLoading}
                        fullWidth
                        id="outlined-basic"
                        label={<InputLabel title="Giá điện" />}
                        variant="outlined"
                        value={inittialStateInfo.electricity_price}
                        onChange={(e) =>
                          setInittialStateInfo({
                            ...inittialStateInfo,
                            electricity_price: e.target.value,
                          })
                        }
                      />
                    </ResponsiveItemWrapper>
                    <ResponsiveItemWrapper>
                      <TextField
                        className={classes.root}
                        disabled={submitLoading}
                        fullWidth
                        id="outlined-basic"
                        label={<InputLabel title="Phí gửi xe máy" />}
                        variant="outlined"
                        value={inittialStateInfo.motor_charge}
                        onChange={(e) =>
                          setInittialStateInfo({
                            ...inittialStateInfo,
                            motor_charge: e.target.value,
                          })
                        }
                      />
                    </ResponsiveItemWrapper>
                    {/* <ItemWrapper>
                            <TextField
                              className={classes.root}
                              disabled={submitLoading}
                              fullWidth
                              id="outlined-basic"
                              label={<InputLabel title="Thang máy" />}
                              variant="outlined"
                              value={inittialStateInfo.elevator}
                              onChange={(e) =>
                                setInittialStateInfo({
                                  ...inittialStateInfo,
                                  elevator: e.target.value,
                                })
                              }
                            />
                          </ItemWrapper> */}
                  </InputRowWrapper>
                  <InputRowWrapper>
                    <ResponsiveItemWrapper>
                      <TextField
                        className={classes.root}
                        disabled={submitLoading}
                        fullWidth
                        id="outlined-basic"
                        label={<InputLabel title="Giá nước" />}
                        variant="outlined"
                        value={inittialStateInfo.water_price}
                        onChange={(e) =>
                          setInittialStateInfo({
                            ...inittialStateInfo,
                            water_price: e.target.value,
                          })
                        }
                      />
                    </ResponsiveItemWrapper>
                    <ResponsiveItemWrapper>
                      <TextField
                        className={classes.root}
                        disabled={submitLoading}
                        fullWidth
                        id="outlined-basic"
                        label={<InputLabel title="Phí làm ngoài giờ" />}
                        variant="outlined"
                        value={inittialStateInfo.overtime}
                        onChange={(e) =>
                          setInittialStateInfo({
                            ...inittialStateInfo,
                            overtime: e.target.value,
                          })
                        }
                      />
                    </ResponsiveItemWrapper>
                    {/* <ItemWrapper>
                            <TextField
                              className={classes.root}
                              disabled={submitLoading}
                              fullWidth
                              id="outlined-basic"
                              label={<InputLabel title="Phí dịch vụ" />}
                              variant="outlined"
                              value={inittialStateInfo.service_charge}
                              onChange={(e) =>
                                setInittialStateInfo({
                                  ...inittialStateInfo,
                                  service_charge: e.target.value,
                                })
                              }
                            />
                          </ItemWrapper> */}
                  </InputRowWrapper>
                  <InputRowWrapper>
                    {/* <ItemWrapper>
                            <TextField
                              className={classes.root}
                              disabled={submitLoading}
                              fullWidth
                              id="outlined-basic"
                              label={<InputLabel title="Thuế VAT" />}
                              variant="outlined"
                              value={inittialStateInfo.vat}
                              onChange={(e) =>
                                setInittialStateInfo({
                                  ...inittialStateInfo,
                                  vat: e.target.value,
                                })
                              }
                            />
                          </ItemWrapper> */}
                  </InputRowWrapper>
                  <InputRowWrapper></InputRowWrapper>
                </Grid>
              </Grid>
            </div>
            <div className="main-image-project">
              <div className={"title-image"}>Bổ sung thông tin nguồn:</div>
              <SourceInfo
                onFormChange={handleSourceInfoChange}
                submitLoading={submitLoading}
              />
            </div>
          </Container>
        </Wrapper>
      )}
    </FullScreenDialogWrapper>
  );
}

export default React.memo(ProjectCreate);
