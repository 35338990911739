import React from "react";

import {
  Wrapper,
  InputRowWrapper,
  ItemWrapper,
  EditorWrapper,
  ImagePreviewWrapper,
  ResponsiveItemWrapper,
  ResponsiveItemWrapper30,
} from "../styledMui";

//Services
import * as projectTypeService from "../../../Services/projectTypeService";
import * as cityService from "../../../Services/cityService";
import { useState, useEffect } from "react";
//Library
import { useNotify, useRecordContext, useRefresh } from "react-admin";
import {
  Container,
  TextField,
  Autocomplete,
  Grid,
  Button,
  styled,
} from "@mui/material";

//Component
import FullScreenDialogWrapper from "../../../component/FullScreenDialogWrapper";
import PageLoadingAPI from "../../../component/PageLoadingAPI";
import ImagePreview from "../../../component/ImgPreview";
import InputLabel from "../../../component/InputLabel";

// Switch  component
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

//Shared data
import { STATUS_PROJECT } from "../../../shared/status";
import { TYPE_PRICE } from "../../../shared/status";

import DialogSubmitLoading from "../../../component/DialogSubmitLoading";
import Editor from "../../../component/Editor";

import { createStyles, makeStyles } from "@material-ui/core/styles";

// call api
import * as request from "../../../utils/httpRequest";
import * as directionService from "../../../Services/directionService";

// image
import classnames from "classnames/bind";
import styleImage from "../../../users/User.module.scss";
import Image from "../../../component/Image";

// icon remove
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import * as projectTypeEstateService from "../../../Services/projectTypeEstateService";
import Paper from "@mui/material/Paper";
import { constants } from "../../../shared/constants";
import FreeSoloCreateOptionDialog from "../../../component/FreeSoloCreateOptionDialog";
import BuildingFloor from "../../components/BuildingFloor";
import LimitTags from "../../components/LimitTags";
import PriceInput from "../../../component/PriceInput";
import SourceInfo from "../../components/SourceInfo";

const user = JSON.parse(
  localStorage.getItem(constants.LOCAL_STORAGE.USER_IDENTITY) as string
);

//Reformat Style
const styles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        paddingTop: "41px",
      },
      "& .css-1z10yd4-MuiFormControl-root-MuiTextField-root": {
        marginTop: 20,
        marginBottom: 20,
      },
    },
  })
);

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
type ErrorTypes = {
  isError: boolean;
  message: String;
};

// main detail-project
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function ProjectDetail({ isOpen, onClose }: Props) {
  const classes = styles();
  const [initialState, setInitialState] = React.useState<any>();
  const [inittialStateInfo, setInittialStateInfo] = React.useState<any>();
  const [projectType, setProjectType] = React.useState<any>();
  const [projectTypeEstate, setProjectTypeEstate] = React.useState<any>();
  const [status, setStatus] = React.useState<any>();
  const [typePrice, setTypePrice] = React.useState<any>();

  //STATE REST API
  const [listProjectType, setListProjectType] = React.useState<any[]>([]);
  const [listProjectTypeEstate, setListProjectTypeEstate] = React.useState<
    any[]
  >([]);

  //LOADING STATE
  const [loading, setLoading] = React.useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);

  //ERROR STATE
  const [nameErr, setNameErr] = React.useState<ErrorTypes | null>();
  const [areaErr, setAreaErr] = React.useState<ErrorTypes | null>();
  const [typeErr, setTypeErr] = React.useState<ErrorTypes | null>();
  const [mapsErr, setMapsErr] = React.useState<ErrorTypes | null>();
  const [statusErr, setStatusErr] = React.useState<ErrorTypes | null>();
  const [keywordErr, setKeywordErr] = React.useState<ErrorTypes | null>();
  const [sourceErr, setSourceErr] = React.useState<ErrorTypes | null>();
  const [city, setCity] = React.useState<any>();
  const [listCity, setListCity] = React.useState<any[]>([]);
  const [district, setDistrict] = React.useState<any>();
  const [ward, setWard] = React.useState<any>();
  const [listDistrict, setListDistrict] = React.useState<any[]>([]);
  const [listWard, setListWard] = React.useState<any[]>([]);
  const [cityErr, setCityErr] = React.useState<ErrorTypes | null>();
  const [districtErr, setDistrictErr] = React.useState<ErrorTypes | null>();
  const [typeEstateErr, setTypeEstateErr] = React.useState<ErrorTypes | null>();
  const [slugErr, setSlugErr] = React.useState<ErrorTypes | null>();
  const [noteErr, setNoteErr] = React.useState<ErrorTypes | null>();
  const [typePriceErr, setTypePriceErr] = React.useState<ErrorTypes | null>();
  const [videosErr, setVideosErr] = React.useState<ErrorTypes | null>();
  const [codeErr, setCodeErr] = React.useState<ErrorTypes | null>();
  const [listDirection, setListDirection] = React.useState<any[]>([]);
  const [mainPriceErr, setMainPriceErr] = React.useState<ErrorTypes | null>();
  const [wardErr, setWardErr] = React.useState<ErrorTypes | null>();
  const [directionErr, setDirectionErr] = React.useState<ErrorTypes | null>();

  //HOOKS
  const record = useRecordContext();

  // checker
  const [checked, setChecked] = React.useState(true);
  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  // checker
  const [current_status, setCurrentStatus] = React.useState(false);
  const handleCurrentStatusChange = (event: any) => {
    setCurrentStatus(event.target.checked);
  };

  // image

  const cx = classnames.bind(styleImage);
  //avatar
  const [pst_image, setPstImage] = React.useState<any[]>([]);
  const removePstImageUtilities = (imgIndex: number) => {
    const newListPstImage = pst_image.filter((_, index) => index !== imgIndex);
    if (pst_image.length === 1) {
      setPstImage([]);
    }
    setPstImage(newListPstImage);
  };

  // image project
  const [image, setImage] = React.useState<any[]>([]);
  const removeImageUtilities = (imgIndex: number) => {
    setImage((prevImage) => {
      const updatedImage = [...prevImage];
      updatedImage.splice(imgIndex, 1);
      return updatedImage;
    });
  };

  const notify = useNotify();
  const refresh = useRefresh();

  // casll api huyện , quận
  const hanhdlCity = async (value: any) => {
    setListDistrict([]);
    const response: any = await request.get(
      `districts/getDistrictByProvice/${value.code}`
    );
    if (response.data) {
      setListDistrict(response.data);
    }
  };

  //call api get xã phường
  const handleDistrict = async (value: any) => {
    setListWard([]);
    const response = await request.get(`wards/getWards/${value.code}`);
    if (response.data) {
      setListWard(response.data);
    }
  };

  //CALL API
  React.useEffect(() => {
    handleProjectTypeEstate(projectTypeEstate);
  }, [projectTypeEstate]);

  const handleProjectTypeEstate = async (value?: any) => {
    if (listProjectType) setListProjectType([]);
    const response = await request.get(
      `project_type/getProjectTypeId/${value.project_type_estate}`
    );
    if (response.data) {
      setListProjectType(response.data);
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      setImage([]);
      setLoading(true);

      const fetchApi = async () => {
        const data = await request.get(
          `project_estate/getProject/${record.id}`
        );

        const listDirectionResult = await directionService.getListDirection();
        setListDirection(listDirectionResult);

        const directionObj = await listDirectionResult.find(
          (item: any) => item.id === data.data.direction_id
        );

        const resultData = {
          ...data.data,
          direction_id: directionObj,
          mainPrice: data.data.main_price,
        };
        setInitialState(resultData);
        // setInittialStateInfo(data.data.project_info);
        setInittialStateInfo((prevState: any) => ({
          ...data.data.project_info,
          source_info:
            data.data.project_info.source_info &&
            typeof data.data.project_info.source_info === "string"
              ? JSON.parse(data.data.project_info.source_info)
              : null,
        }));
        setProjectTypeEstate(data.data.project_type_estate.id);
        setImage(data?.data?.project_image?.map((value: any) => value.image));

        const response = await request.get(
          `project_type/getProjectTypeId/${data.data.project_type_estate}`
        );
        if (response.data) {
          const projectTypeObj = await response.data.find(
            (item: any) => item.id === data.data.type
          );
          console.log(projectTypeObj);
          setProjectType(projectTypeObj);
        }

        const statusObj = await STATUS_PROJECT.find(
          (item: any) => item.id === data.data.status
        );
        const typePriceObj = await TYPE_PRICE.find(
          (item: any) => item.id === data.data.type_price
        );

        //api loại hình
        const listProjectTypeEstateResult =
          await projectTypeEstateService.getListTypeEstate();
        const projectTypeEstateObj = await listProjectTypeEstateResult.find(
          (item: any) => item.id === data.data.project_type_estate
        );
        // api Tỉnh thành
        const listCityResult = await cityService.getListCity();
        const cityObj = await listCityResult.find(
          (item: any) => item.id === data.data.provinces
        );

        console.log(data.data.provinces);
        // api quận / huyện
        const listDistrictResult = await request.get(
          `districts/getDistrictByProvice/${data.data.provinces}`
        );
        console.log(listDistrictResult);

        const districtObj = await listDistrictResult.data.find(
          (item: any) => item.id === data.data.districts
        );

        // api xã / phường
        const listWardResult = await request.get(
          `wards/getWards/${districtObj.code}`
        );

        const wardObj = await listWardResult.data.find(
          (item: any) => item.id === data.data.wards
        );
        setInitialState((preState: any) => ({ ...preState, ward: wardObj }));

        //SET STATE
        setChecked(data.data.is_hot);
        setCurrentStatus(data.data.status_current);
        // setListProjectType(listProjectTypeResult);
        setListProjectTypeEstate(listProjectTypeEstateResult);
        setProjectTypeEstate(projectTypeEstateObj);
        setListCity(listCityResult);
        setListDistrict(listDistrictResult.data);
        setListWard(listWardResult.data);

        setCity(cityObj);
        setDistrict(districtObj);
        setWard(wardObj);
        setStatus(statusObj);
        setTypePrice(typePriceObj);

        setLoading(false);

        setSubmitLoading(true);
      };
      fetchApi();
    }
  }, [isOpen]);

  const handleSubmit = () => {
    let pass = true;
    setNameErr(null);
    setAreaErr(null);
    setTypeErr(null);
    setStatusErr(null);
    setKeywordErr(null);
    setSourceErr(null);
    setCityErr(null);
    setDistrictErr(null);
    setTypeEstateErr(null);
    setSlugErr(null);
    setNoteErr(null);
    setCodeErr(null);
    setTypePriceErr(null);
    setMainPriceErr(null);
    setWardErr(null);
    setDirectionErr(null);
    if (!initialState?.name?.trim()) {
      pass = false;
      setSubmitLoading(false);
      setNameErr({
        isError: true,
        message: "Tên dự án không được để trống!",
      });
    } else if (initialState?.name?.length < 3) {
      pass = false;
      setSubmitLoading(false);
      setNameErr({
        isError: true,
        message: "Tên dự án phải chứa ít nhât 3 ký tự!",
      });
    }
    if (!projectType?.id) {
      pass = false;
      setSubmitLoading(false);
      setTypeErr({
        isError: true,
        message: "Loại dự án  không được để trống!",
      });
    }
    if (!projectTypeEstate?.id) {
      pass = false;
      setSubmitLoading(false);
      setTypeErr({
        isError: true,
        message: "Loại hình  không được để trống!",
      });
    }

    if (!status?.label) {
      pass = false;
      setSubmitLoading(false);
      setStatusErr({
        isError: true,
        message: "Trạng thái không được để trống!",
      });
    }
    if (!city?.id) {
      pass = false;
      setCityErr({
        isError: true,
        message: "Tỉnh thành không được để trống!",
      });
    }
    if (!initialState?.code?.trim()) {
      pass = false;
      setCodeErr({
        isError: true,
        message: "Mã sản phẩm không được để trống!",
      });
    }
    if (!district?.id) {
      pass = false;
      setDistrictErr({
        isError: true,
        message: " Quận/huyện không được để trống!",
      });
    }
    console.log(ward);
    if (!initialState?.ward?.id) {
      pass = false;
      setWardErr({
        isError: true,
        message: " Xã/Phường không được để trống!",
      });
    }

    if (!initialState?.direction_id?.id) {
      pass = false;
      setDirectionErr({
        isError: true,
        message: "Hướng không được để trống!",
      });
    }

    if (!initialState.keyword || initialState.keyword?.length <= 0) {
      pass = false;
      setKeywordErr({
        isError: true,
        message: "Từ khóa không được để trống!",
      });
    }
    if (!initialState.source_id) {
      pass = false;
      setSourceErr({
        isError: true,
        message: "Nguồn không được để trống!",
      });
    }

    // if((initialState?.type_price == 1 || initialState?.type_price.id == 1 ||
    //   initialState?.type_price == 2 || initialState?.type_price.id == 2) && !initialState?.mainPrice) {
    //   pass = false;
    //   setMainPriceErr({
    //     isError: true,
    //     message: "Giá bán không được để trống!",
    //   })
    // }

    if (pass && !submitLoading) {
      let dtImg: any[] = [];
      if (image.length > 0) {
        image.map((item) => {
          dtImg = [
            ...dtImg,
            {
              image: item.name,
            },
          ];
        });
      } else {
        dtImg = initialState?.project_image
          ? initialState?.project_image
          : null;
      }

      // data item detail project
      const {
        name,
        content,
        maps,
        status,
        price,
        mainPrice,
        city,
        district,
        ward,
        area,
        slug,
        videos,
        meta_title,
        meta_description,
        meta_keyword,
        note_admin,
        code,
        address,
      } = initialState;

      const {
        building_size,
        ceiling_height,
        harmonic,
        elevator,
        parking,
        service_charge,
        vat,
        car_charge,
        motor_charge,
        overtime,
        main_door,
        balcony_direction,
        interior,
        number_floors,
        facade,
        bedroom,
        toilet,
        juridical,
        empty_floor,
        electricity_price,
        water_price,
      } = inittialStateInfo;

      const data = {
        name: name as string,
        maps: maps as string,
        type: projectType ? projectType?.id : initialState.type,
        project_type_estate: projectTypeEstate
          ? projectTypeEstate?.id
          : initialState.project_type_estate,
        content: content,
        status: status?.id ? status.id : initialState?.status || 0,
        price: price,
        mainPrice: mainPrice,
        area: area,
        videos: videos,
        is_hot: checked ? 1 : 0,
        status_current: current_status ? 1 : 0,
        provinces: city ? city?.id : initialState?.provinces,
        districts: district ? district?.id : initialState?.districts,
        wards: ward ? ward?.id : initialState?.wards,
        source_id: initialState?.source_id,
        direction_id: initialState?.direction_id?.id
          ? initialState?.direction_id?.id
          : null,
        pst_image: initialState?.pst_image
          ? initialState?.pst_image
          : pst_image,
        slug: slug,
        meta_title: meta_title,
        meta_description: meta_description,
        meta_keyword: meta_keyword,
        note_admin: note_admin,
        code: code,
        address: address,
        type_price: typePrice?.id,
        building_size: building_size,
        ceiling_height: ceiling_height,
        harmonic: harmonic,
        elevator: elevator,
        parking: parking,
        service_charge: service_charge,
        vat: vat,
        car_charge: car_charge,
        motor_charge: motor_charge,
        overtime: overtime,
        main_door: main_door,
        balcony_direction: balcony_direction,
        interior: interior,
        number_floors: number_floors,
        facade: facade,
        bedroom: bedroom,
        toilet: toilet,
        juridical: juridical,
        empty_floor: empty_floor,
        electricity_price: electricity_price,
        water_price: water_price,
      };
      const submitObj = new FormData();

      //list floor
      if (initialState?.listFloor?.length > 0) {
        const updatedData = initialState?.listFloor.map((item: any) => {
          return { ...item, status: item.status.id };
        });
        submitObj.append("listFloor", JSON.stringify(updatedData));
      }

      //keyword
      if (initialState?.keyword?.length > 0) {
        submitObj.append("keyword", JSON.stringify(initialState?.keyword));
      }

      // project image
      if (image) {
        image.map((item: any, index) => {
          submitObj.append(`project_image[${index}]`, item);
        });
      }

      //avatar
      if (pst_image) {
        pst_image.map((item: any) => {
          submitObj.append(`pst_image`, item);
        });
      }

      console.log(data);
      Object.keys(data).map((key) =>
        // @ts-ignore
        submitObj.append(key, data[key])
      );
      const submit = async () => {
        const result = await request.post(
          `/project_estate/editProject?id=${record.id}`,
          submitObj
        );
        if (result.status === 200) {
          notify(`Sửa dự án thành công`, {
            type: "success",
            undoable: true,
          });
          onClose();
          setTimeout(() => {
            setSubmitLoading(false);
          }, 500);
          refresh();
        } else {
          notify(`Sửa dự án thất bại`, {
            type: "error",
            undoable: true,
          });
          setSubmitLoading(false);
        }
      };
      submit();
    }
  };

  const onSelectSource = (value: any) => {
    let val = typeof value == "object" ? value.id : value;
    setInitialState({ ...initialState, source_id: val });
    setSourceErr(null);
  };

  const onDataBuildingFloor = (value: any) => {
    setInitialState({ ...initialState, listFloor: value });
  };

  const onSelectKeyword = (value: any) => {
    let lstResult: any = [];
    if (value.length > 0) {
      value.map((i: any) => {
        if (i.inputValue) {
          lstResult = [...lstResult, i.inputValue];
        } else {
          lstResult = [...lstResult, i.id];
        }
      });
    }
    setInitialState({ ...initialState, keyword: lstResult });
    setKeywordErr(null);
  };

  return (
    <FullScreenDialogWrapper
      title="Chi tiết dự án"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      isSubmit={false}
    >
      {loading ? (
        <PageLoadingAPI />
      ) : (
        <Wrapper>
          <Container>
            <InputRowWrapper>
              <ResponsiveItemWrapper>
                <TextField
                  className={classes.root}
                  fullWidth
                  inputProps={{ readOnly: submitLoading }}
                  error={nameErr?.isError}
                  helperText={nameErr?.message}
                  variant="outlined"
                  label={<InputLabel title="Tên dự án" required />}
                  value={initialState?.name}
                  onChange={(e) =>
                    setInitialState({
                      ...initialState,
                      name: e.target.value,
                      meta_title: e.target.value,
                      meta_description: e.target.value,
                      meta_keyword: e.target.value,
                    })
                  }
                />
              </ResponsiveItemWrapper>
              <ResponsiveItemWrapper>
                <TextField
                  className={classes.root}
                  inputProps={{ readOnly: submitLoading }}
                  fullWidth
                  error={mapsErr?.isError}
                  helperText={mapsErr?.message}
                  id="outlined-basic"
                  label={<InputLabel title="Google maps" required />}
                  variant="outlined"
                  value={initialState?.maps}
                  onChange={(e) =>
                    setInitialState({
                      ...initialState,
                      maps: e.target.value,
                    })
                  }
                />
              </ResponsiveItemWrapper>
            </InputRowWrapper>
            <InputRowWrapper>
              <ResponsiveItemWrapper30>
                <TextField
                  className={classes.root}
                  inputProps={{ readOnly: submitLoading }}
                  fullWidth
                  error={codeErr?.isError}
                  helperText={codeErr?.message}
                  id="outlined-basic"
                  label={<InputLabel title="Mã sản phẩm" required />}
                  variant="outlined"
                  value={initialState?.code}
                  onChange={(e) =>
                    setInitialState({
                      ...initialState,
                      code: e.target.value,
                    })
                  }
                />
              </ResponsiveItemWrapper30>
              <ResponsiveItemWrapper30>
                <Autocomplete
                  readOnly={submitLoading}
                  fullWidth
                  id="combo-box-demo"
                  options={listProjectTypeEstate}
                  renderInput={(params) => (
                    <TextField
                      className={classes.root}
                      error={typeEstateErr?.isError}
                      helperText={typeEstateErr?.message}
                      value={initialState.project_type_estate}
                      {...params}
                      variant="outlined"
                      label={<InputLabel title="Loại hình" required />}
                    />
                  )}
                  value={projectTypeEstate}
                  onChange={(e, value) => {
                    setInitialState({
                      ...initialState,
                      project_type_estate: value,
                    });
                    setProjectTypeEstate(value);
                    handleProjectTypeEstate(value);
                    setProjectType(null);
                    setInittialStateInfo({
                      ...inittialStateInfo,
                      building_size: "",
                      ceiling_height: "",
                      harmonic: "",
                      elevator: "",
                      parking: "",
                      service_charge: "",
                      vat: "",
                      car_charge: "",
                      motor_charge: "",
                      overtime: "",
                    });
                  }}
                  getOptionLabel={(option) => option.name}
                />
              </ResponsiveItemWrapper30>
              <ResponsiveItemWrapper30>
                <Autocomplete
                  readOnly={submitLoading}
                  fullWidth
                  id="combo-box-demo"
                  options={listProjectType}
                  renderInput={(params) => (
                    <TextField
                      className={classes.root}
                      error={typeErr?.isError}
                      helperText={typeErr?.message}
                      {...params}
                      variant="outlined"
                      label={<InputLabel title="Loại dự án" required />}
                    />
                  )}
                  value={projectType}
                  onChange={(e, value) => {
                    setInitialState({
                      ...initialState,
                      type: value,
                    });
                    setProjectType(value);
                    setTypeErr(null);
                  }}
                  getOptionLabel={(option) => option.name}
                />
              </ResponsiveItemWrapper30>
            </InputRowWrapper>
            <InputRowWrapper>
              <ResponsiveItemWrapper30>
                <Autocomplete
                  readOnly={submitLoading}
                  fullWidth
                  id="combo-box-demo"
                  options={listCity}
                  renderInput={(params) => (
                    <TextField
                      className={classes.root}
                      error={cityErr?.isError}
                      helperText={cityErr?.message}
                      value={initialState.city}
                      {...params}
                      variant="outlined"
                      label={<InputLabel title="Tỉnh" required />}
                    />
                  )}
                  value={city}
                  onChange={(e, value) => {
                    setInitialState({
                      ...initialState,
                      city: value,
                    });
                    setCity(value);
                    hanhdlCity(value);
                  }}
                  getOptionLabel={(option) => option.name}
                />
              </ResponsiveItemWrapper30>
              <ResponsiveItemWrapper30>
                <Autocomplete
                  readOnly={submitLoading}
                  fullWidth
                  id="combo-box-demo"
                  options={listDistrict}
                  renderInput={(params) => (
                    <TextField
                      className={classes.root}
                      error={districtErr?.isError}
                      helperText={districtErr?.message}
                      {...params}
                      variant="outlined"
                      value={initialState.district}
                      label={<InputLabel title="Huyện/Quận" required />}
                    />
                  )}
                  value={district}
                  onChange={(e, value) => {
                    handleDistrict(value);
                    setInitialState({
                      ...initialState,
                      district: value,
                    });
                    setDistrict(value);
                  }}
                  getOptionLabel={(option) => option.name}
                />
              </ResponsiveItemWrapper30>
              <ResponsiveItemWrapper30>
                <Autocomplete
                  readOnly={submitLoading}
                  fullWidth
                  id="combo-box-demo"
                  options={listWard}
                  renderInput={(params) => (
                    <TextField
                      className={classes.root}
                      error={wardErr?.isError}
                      helperText={wardErr?.message}
                      {...params}
                      variant="outlined"
                      value={initialState.ward}
                      label={<InputLabel title="Xã/Phường" required />}
                    />
                  )}
                  value={ward}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => {
                    setInitialState({
                      ...initialState,
                      ward: value,
                    });
                    setWardErr(null);
                  }}
                />
              </ResponsiveItemWrapper30>
            </InputRowWrapper>

            <InputRowWrapper>
              <ResponsiveItemWrapper>
                <Autocomplete
                  readOnly={submitLoading}
                  fullWidth
                  id="combo-box-demo"
                  options={TYPE_PRICE}
                  renderInput={(params) => (
                    <TextField
                      className={classes.root}
                      error={typePriceErr?.isError}
                      helperText={typePriceErr?.message}
                      {...params}
                      variant="outlined"
                      label={<InputLabel title="Đơn vị" required />}
                    />
                  )}
                  value={typePrice}
                  onChange={(e, value) => {
                    setInitialState({
                      ...initialState,
                      type_price: value,
                    });
                    setTypePrice(value);
                  }}
                  getOptionLabel={(option) => option.label}
                />
              </ResponsiveItemWrapper>
              <ResponsiveItemWrapper>
                <Autocomplete
                  readOnly={submitLoading}
                  fullWidth
                  id="combo-box-demo"
                  options={STATUS_PROJECT}
                  renderInput={(params) => (
                    <TextField
                      className={classes.root}
                      error={statusErr?.isError}
                      helperText={statusErr?.message}
                      {...params}
                      variant="outlined"
                      label={<InputLabel title="Trạng thái" required />}
                    />
                  )}
                  value={status}
                  onChange={(e, value) => {
                    setInitialState({
                      ...initialState,
                      status: value,
                    });
                    setStatus(value);
                  }}
                  getOptionLabel={(option) => option.label}
                />
              </ResponsiveItemWrapper>
            </InputRowWrapper>

            <InputRowWrapper>
              <ResponsiveItemWrapper>
                <TextField
                  className={classes.root}
                  inputProps={{ readOnly: submitLoading }}
                  fullWidth
                  id="outlined-basic"
                  label={<InputLabel title="Địa chỉ" />}
                  variant="outlined"
                  value={initialState?.address}
                  onChange={(e) =>
                    setInitialState({
                      ...initialState,
                      address: e.target.value,
                    })
                  }
                />
              </ResponsiveItemWrapper>
              <ResponsiveItemWrapper>
                <FreeSoloCreateOptionDialog
                  submitLoading={submitLoading}
                  title={"Nguồn"}
                  onSelect={onSelectSource}
                  val={initialState?.source_id}
                  statusError={sourceErr || null}
                />
              </ResponsiveItemWrapper>
            </InputRowWrapper>

            <InputRowWrapper>
              <BuildingFloor
                onDataFloor={onDataBuildingFloor}
                val={initialState?.list_floor}
                readonlyPrice={
                  initialState?.type_price == 1 ||
                  initialState?.type_price.id == 1 ||
                  initialState?.type_price == 2 ||
                  initialState?.type_price.id == 2
                    ? true
                    : false
                }
                isDollar={
                  initialState?.type_price.id == 2 ||
                  initialState?.type_price == 2
                    ? true
                    : false
                }
                submitLoading={submitLoading}
              />
            </InputRowWrapper>

            <InputRowWrapper>
              <ResponsiveItemWrapper30>
                <TextField
                  className={classes.root}
                  inputProps={{ readOnly: submitLoading }}
                  fullWidth
                  id="outlined-basic"
                  label={<InputLabel title="Meta title" />}
                  variant="outlined"
                  value={initialState?.meta_title}
                  onChange={(e) =>
                    setInitialState({
                      ...initialState,
                      meta_title: e.target.value,
                    })
                  }
                  helperText={`${initialState?.meta_title.length}/70`}
                />
              </ResponsiveItemWrapper30>
              <ResponsiveItemWrapper30>
                <TextField
                  className={classes.root}
                  inputProps={{ readOnly: submitLoading }}
                  fullWidth
                  id="outlined-basic"
                  label={<InputLabel title="Meta description" />}
                  variant="outlined"
                  value={initialState?.meta_description}
                  onChange={(e) =>
                    setInitialState({
                      ...initialState,
                      meta_description: e.target.value,
                    })
                  }
                  helperText={`${initialState?.meta_description.length}/170`}
                />
              </ResponsiveItemWrapper30>
              <ResponsiveItemWrapper30>
                <TextField
                  className={classes.root}
                  inputProps={{ readOnly: submitLoading }}
                  fullWidth
                  id="outlined-basic"
                  label={<InputLabel title="Meta keyword" />}
                  variant="outlined"
                  value={initialState?.meta_keyword}
                  onChange={(e) =>
                    setInitialState({
                      ...initialState,
                      meta_keyword: e.target.value,
                    })
                  }
                />
              </ResponsiveItemWrapper30>
            </InputRowWrapper>
            <InputRowWrapper>
              <ResponsiveItemWrapper>
                <TextField
                  className={classes.root}
                  inputProps={{ readOnly: submitLoading }}
                  fullWidth
                  error={slugErr?.isError}
                  helperText={slugErr?.message}
                  variant="outlined"
                  label={<InputLabel title="Đường dẫn" />}
                  value={initialState?.slug}
                  onChange={(e) =>
                    setInitialState({
                      ...initialState,
                      slug: e.target.value,
                    })
                  }
                />
              </ResponsiveItemWrapper>
              <ResponsiveItemWrapper>
                <TextField
                  className={classes.root}
                  inputProps={{ readOnly: submitLoading }}
                  fullWidth
                  error={videosErr?.isError}
                  helperText={videosErr?.message}
                  variant="outlined"
                  label={<InputLabel title="Đường dẫn videos" />}
                  value={initialState?.videos}
                  onChange={(e) =>
                    setInitialState({
                      ...initialState,
                      videos: e.target.value,
                    })
                  }
                />
              </ResponsiveItemWrapper>
            </InputRowWrapper>
            <InputRowWrapper>
              <ResponsiveItemWrapper>
                <Autocomplete
                  readOnly={submitLoading}
                  fullWidth
                  id="combo-box-demo"
                  options={listDirection}
                  renderInput={(params) => (
                    <TextField
                      className={classes.root}
                      error={directionErr?.isError}
                      helperText={directionErr?.message}
                      value={initialState?.direction_id}
                      {...params}
                      variant="outlined"
                      label={<InputLabel title="Hướng" required />}
                    />
                  )}
                  value={initialState?.direction_id}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    setInitialState({
                      ...initialState,
                      direction_id: value,
                    });
                    setDirectionErr(null);
                  }}
                />
              </ResponsiveItemWrapper>
              <ResponsiveItemWrapper>
                <LimitTags
                  submitLoading={submitLoading}
                  title="Từ khóa"
                  onSelect={onSelectKeyword}
                  val={initialState?.keyword}
                  statusError={keywordErr || null}
                />
              </ResponsiveItemWrapper>
            </InputRowWrapper>

            <InputRowWrapper>
              <ResponsiveItemWrapper>
                <FormControlLabel
                  control={<Switch checked={checked} onChange={handleChange} />}
                  label="Dự án nổi bật"
                  labelPlacement="start"
                  disabled={submitLoading}
                />
              </ResponsiveItemWrapper>
            </InputRowWrapper>

            <Grid>
              <div className={"main-image"}>
                <div className={cx("upload-image-wrapper")}>
                  <div className={"title-image"}>Avatar</div>
                  {pst_image?.length > 0 ? (
                    <div className={cx("image-show-wrapper")}>
                      {pst_image.map((pst_image: any, index: number) => (
                        <a
                          href={`${process.env.REACT_APP_BASE_END_POINT_URL}${pst_image}`}
                          target="_blank"
                        >
                          <ImagePreview
                            key={index}
                            src={URL.createObjectURL(pst_image)}
                            showRemoveButton={false}
                          />
                        </a>
                      ))}
                    </div>
                  ) : (
                    <a href={initialState?.pst_image} target="_blank">
                      <Image
                        src={initialState?.pst_image}
                        alt={""}
                        className={cx("img-user-edit")}
                      />
                    </a>
                  )}
                </div>
              </div>
            </Grid>
            <Grid>
              <div className={"main-image"}>
                <div className={cx("upload-image-wrapper")}>
                  <div className={"title-image"}>Hình ảnh</div>
                  {image?.length > 0 ? (
                    <div className={cx("image-show-wrapper")}>
                      <Grid
                        style={{ marginTop: "10px" }}
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        {image.map((item: any, index: number) => (
                          <Grid item xs={3}>
                            <Item>
                              <a href={item} target="_blank">
                                <ImagePreview
                                  key={index}
                                  src={
                                    item instanceof File
                                      ? URL.createObjectURL(item)
                                      : item
                                  }
                                  showRemoveButton={false}
                                />
                              </a>
                            </Item>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  ) : (
                    <a href={initialState?.image} target="_blank">
                      <Image
                        src={initialState?.image}
                        alt={""}
                        className={cx("img-user-edit")}
                      />
                    </a>
                  )}
                </div>
              </div>
            </Grid>
            <EditorWrapper>
              <Editor
                data={initialState?.content}
                onChange={(event: any, editor: any) => {
                  const data = editor.getData();
                  setInitialState({
                    ...initialState,
                    content: data,
                  });
                }}
                submitLoading={submitLoading}
              />
            </EditorWrapper>

            <EditorWrapper>
              <div className={"title-image"}>Note</div>
              <Editor
                data={initialState?.note_admin}
                onChange={(event: any, editor: any) => {
                  const data = editor.getData();
                  setInitialState({
                    ...initialState,
                    note_admin: data,
                  });
                }}
                submitLoading={submitLoading}
              />
            </EditorWrapper>

            <div className="main-image-project">
              <div className={"title-image"}>Thông tin mô tả:</div>

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <InputRowWrapper>
                    <ResponsiveItemWrapper>
                      <TextField
                        className={classes.root}
                        inputProps={{ readOnly: submitLoading }}
                        fullWidth
                        id="outlined-basic"
                        label={<InputLabel title="Quy mô tòa nhà" />}
                        variant="outlined"
                        value={inittialStateInfo?.building_size}
                        onChange={(e) =>
                          setInittialStateInfo({
                            ...inittialStateInfo,
                            building_size: e.target.value,
                          })
                        }
                      />
                    </ResponsiveItemWrapper>
                    <ResponsiveItemWrapper>
                      <TextField
                        className={classes.root}
                        inputProps={{ readOnly: submitLoading }}
                        fullWidth
                        id="outlined-basic"
                        label={<InputLabel title="Phí gửi ô tô" />}
                        variant="outlined"
                        value={inittialStateInfo?.car_charge}
                        onChange={(e) =>
                          setInittialStateInfo({
                            ...inittialStateInfo,
                            car_charge: e.target.value,
                          })
                        }
                      />
                    </ResponsiveItemWrapper>
                    {/* <ItemWrapper>
                            <TextField
                              className={classes.root}
                              disabled={submitLoading}
                              fullWidth
                              id="outlined-basic"
                              label={<InputLabel title="Tầng trống" />}
                              variant="outlined"
                              value={inittialStateInfo?.empty_floor}
                              onChange={(e) =>
                                setInittialStateInfo({
                                  ...inittialStateInfo,
                                  empty_floor: e.target.value,
                                })
                              }
                            />
                          </ItemWrapper> */}
                  </InputRowWrapper>
                  <InputRowWrapper>
                    <ResponsiveItemWrapper>
                      <TextField
                        className={classes.root}
                        inputProps={{ readOnly: submitLoading }}
                        fullWidth
                        id="outlined-basic"
                        label={<InputLabel title="Giá điện" />}
                        variant="outlined"
                        value={inittialStateInfo?.electricity_price}
                        onChange={(e) =>
                          setInittialStateInfo({
                            ...inittialStateInfo,
                            electricity_price: e.target.value,
                          })
                        }
                      />
                    </ResponsiveItemWrapper>
                    <ResponsiveItemWrapper>
                      <TextField
                        className={classes.root}
                        inputProps={{ readOnly: submitLoading }}
                        fullWidth
                        id="outlined-basic"
                        label={<InputLabel title="Phí gửi xe máy" />}
                        variant="outlined"
                        value={inittialStateInfo?.motor_charge}
                        onChange={(e) =>
                          setInittialStateInfo({
                            ...inittialStateInfo,
                            motor_charge: e.target.value,
                          })
                        }
                      />
                    </ResponsiveItemWrapper>
                    {/* <ItemWrapper>
                            <TextField
                              className={classes.root}
                              disabled={submitLoading}
                              fullWidth
                              id="outlined-basic"
                              label={<InputLabel title="Thang máy" />}
                              variant="outlined"
                              value={inittialStateInfo?.elevator}
                              onChange={(e) =>
                                setInittialStateInfo({
                                  ...inittialStateInfo,
                                  elevator: e.target.value,
                                })
                              }
                            />
                          </ItemWrapper> */}
                  </InputRowWrapper>
                  <InputRowWrapper>
                    <ResponsiveItemWrapper>
                      <TextField
                        className={classes.root}
                        inputProps={{ readOnly: submitLoading }}
                        fullWidth
                        id="outlined-basic"
                        label={<InputLabel title="Giá nước" />}
                        variant="outlined"
                        value={inittialStateInfo?.water_price}
                        onChange={(e) =>
                          setInittialStateInfo({
                            ...inittialStateInfo,
                            water_price: e.target.value,
                          })
                        }
                      />
                    </ResponsiveItemWrapper>
                    <ResponsiveItemWrapper>
                      <TextField
                        className={classes.root}
                        inputProps={{ readOnly: submitLoading }}
                        fullWidth
                        id="outlined-basic"
                        label={<InputLabel title="Phí làm ngoài giờ" />}
                        variant="outlined"
                        value={inittialStateInfo?.overtime}
                        onChange={(e) =>
                          setInittialStateInfo({
                            ...inittialStateInfo,
                            overtime: e.target.value,
                          })
                        }
                      />
                    </ResponsiveItemWrapper>
                    {/* <ItemWrapper>
                            <TextField
                              className={classes.root}
                              disabled={submitLoading}
                              fullWidth
                              id="outlined-basic"
                              label={<InputLabel title="Phí dịch vụ" />}
                              variant="outlined"
                              value={inittialStateInfo?.service_charge}
                              onChange={(e) =>
                                setInittialStateInfo({
                                  ...inittialStateInfo,
                                  service_charge: e.target.value,
                                })
                              }
                            />
                          </ItemWrapper> */}
                  </InputRowWrapper>
                  <InputRowWrapper>
                    {/* <ItemWrapper>
                            <TextField
                              className={classes.root}
                              disabled={submitLoading}
                              fullWidth
                              id="outlined-basic"
                              label={<InputLabel title="Thuế VAT" />}
                              variant="outlined"
                              value={inittialStateInfo?.vat}
                              onChange={(e) =>
                                setInittialStateInfo({
                                  ...inittialStateInfo,
                                  vat: e.target.value,
                                })
                              }
                            />
                          </ItemWrapper> */}
                  </InputRowWrapper>
                  <InputRowWrapper></InputRowWrapper>
                </Grid>
              </Grid>
            </div>

            <div className="main-image-project">
              <div className={"title-image"}>Bổ sung thông tin nguồn:</div>
              <SourceInfo
                onFormChange={() => {}}
                submitLoading={submitLoading}
                data={inittialStateInfo?.source_info}
              />
            </div>
          </Container>
        </Wrapper>
      )}
    </FullScreenDialogWrapper>
  );
}

export default React.memo(ProjectDetail);
