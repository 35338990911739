import {
  TextField,
  Autocomplete,
  Container,
  Grid,
  Button,
} from "@mui/material";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  InputRowWrapper,
  ResponsiveItemWrapper,
} from "../../dialogs/styledMui";
import React, { FC, useState } from "react";
import InputLabel from "../../../component/InputLabel";
import { ISourceInfo } from "../../../types";

const styles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        paddingTop: "41px",
      },
      "& .css-1z10yd4-MuiFormControl-root-MuiTextField-root": {
        marginTop: 20,
        marginBottom: 20,
      },
    },
  })
);

interface FormComponentProps {
  onFormChange: (data: ISourceInfo) => void;
  submitLoading: boolean;
  data?: ISourceInfo;
}

const SourceInfo: FC<FormComponentProps> = ({
  submitLoading,
  onFormChange,
  data,
}) => {
  const classes = styles();
  const [dataForm, setDataForm] = useState<ISourceInfo>(
    data || {
      brokerageFee: "",
      depositAmount: "",
      paymentSchedule: "",
      setupTime: "",
      workingHours: "",
      overtimeFee: "",
      airConditioningElectricity: "",
      employeeDensity: "",
      includedCosts: "",
      notes: "",
    }
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedForm = {
      ...dataForm,
      [name]: value,
    };
    setDataForm(updatedForm);

    onFormChange(updatedForm);
  };

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <InputRowWrapper>
          <ResponsiveItemWrapper>
            <TextField
              className={classes.root}
              disabled={submitLoading}
              fullWidth
              id="outlined-basic"
              label={<InputLabel title="Phí môi giới" />}
              variant="outlined"
              name="brokerageFee"
              value={dataForm.brokerageFee}
              onChange={handleInputChange}
            />
          </ResponsiveItemWrapper>
          <ResponsiveItemWrapper>
            <TextField
              className={classes.root}
              disabled={submitLoading}
              fullWidth
              id="outlined-basic"
              label={<InputLabel title="Tiền đặt cọc" />}
              variant="outlined"
              name="depositAmount"
              value={dataForm.depositAmount}
              onChange={handleInputChange}
            />
          </ResponsiveItemWrapper>
        </InputRowWrapper>
        <InputRowWrapper>
          <ResponsiveItemWrapper>
            <TextField
              className={classes.root}
              disabled={submitLoading}
              fullWidth
              id="outlined-basic"
              label={<InputLabel title="Tiến độ thanh toán" />}
              variant="outlined"
              name="paymentSchedule"
              value={dataForm.paymentSchedule}
              onChange={handleInputChange}
            />
          </ResponsiveItemWrapper>
          <ResponsiveItemWrapper>
            <TextField
              className={classes.root}
              disabled={submitLoading}
              fullWidth
              id="outlined-basic"
              label={<InputLabel title="Thời gian setup" />}
              variant="outlined"
              name="setupTime"
              value={dataForm.setupTime}
              onChange={handleInputChange}
            />
          </ResponsiveItemWrapper>
        </InputRowWrapper>
        <InputRowWrapper>
          <ResponsiveItemWrapper>
            <TextField
              className={classes.root}
              disabled={submitLoading}
              fullWidth
              id="outlined-basic"
              label={<InputLabel title="Thời gian làm việc" />}
              variant="outlined"
              name="workingHours"
              value={dataForm.workingHours}
              onChange={handleInputChange}
            />
          </ResponsiveItemWrapper>
          <ResponsiveItemWrapper>
            <TextField
              className={classes.root}
              disabled={submitLoading}
              fullWidth
              id="outlined-basic"
              label={<InputLabel title="Phí ngoài giờ" />}
              variant="outlined"
              name="overtimeFee"
              value={dataForm.overtimeFee}
              onChange={handleInputChange}
            />
          </ResponsiveItemWrapper>
        </InputRowWrapper>
        <InputRowWrapper>
          <ResponsiveItemWrapper>
            <TextField
              className={classes.root}
              disabled={submitLoading}
              fullWidth
              id="outlined-basic"
              label={<InputLabel title="Điện điều hòa" />}
              variant="outlined"
              name="airConditioningElectricity"
              value={dataForm.airConditioningElectricity}
              onChange={handleInputChange}
            />
          </ResponsiveItemWrapper>
          <ResponsiveItemWrapper>
            <TextField
              className={classes.root}
              disabled={submitLoading}
              fullWidth
              id="outlined-basic"
              label={<InputLabel title="Mật độ nhân viên" />}
              variant="outlined"
              name="employeeDensity"
              value={dataForm.employeeDensity}
              onChange={handleInputChange}
            />
          </ResponsiveItemWrapper>
        </InputRowWrapper>
        <InputRowWrapper>
          <ResponsiveItemWrapper>
            <TextField
              className={classes.root}
              disabled={submitLoading}
              fullWidth
              id="outlined-basic"
              label={<InputLabel title="Chi phí bao gồm trong giá thuê" />}
              variant="outlined"
              name="includedCosts"
              value={dataForm.includedCosts}
              onChange={handleInputChange}
            />
          </ResponsiveItemWrapper>
          <ResponsiveItemWrapper>
            <TextField
              className={classes.root}
              disabled={submitLoading}
              fullWidth
              id="outlined-basic"
              label={<InputLabel title="Ghi chú" />}
              variant="outlined"
              name="notes"
              value={dataForm.notes}
              onChange={handleInputChange}
            />
          </ResponsiveItemWrapper>
        </InputRowWrapper>
      </Grid>
    </Grid>
  );
};
export default SourceInfo;
