import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Container,
  TextField,
  ImageListItem,
  ImageList,
  IconButton,
  Tooltip,
} from "@mui/material";
import HttpDataService from "../../../../dataProvider/http.service";
import InputLabel from "../../../../component/InputLabel";
import { useRecordContext, useRefresh } from "react-admin";
import DialogFormCustomer from "../../../../component/DialogFormCustomer";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { styled as styleSystem } from "@mui/system";
import { commonFunction } from "../../../../component/Common";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Editor from "../../../../component/Editor";
import PriceInput from "../../../../component/PriceInput";
import ImagePreview from "../../../../component/ImgPreview";
import AddIcon from "@mui/icons-material/Add";
// const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);
import styles from "../../../../projectEstate/Project.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

type ErrorTypes = {
  isError: boolean;
  message: String;
};

export const InputRowWrapper = styleSystem(Box)({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  "@media (max-width: 1024px)": {
    display: "flex",
    flexDirection: "column",
  },
});
export const ItemWrapper = styleSystem("div")({
  padding: "10px 20px",
  width: "50%",
  "@media (max-width: 1024px)": {
    width: "100%",
  },
});
export const TitleWrapper = styleSystem("div")({
  padding: "10px 20px",
  width: "100%",
  "@media (max-width: 1024px)": {
    width: "100%",
  },
});
export const Wrapper = styleSystem(Box)({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  "@media (max-width: 1024px)": {
    width: "100%",
  },
});

const styled = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        paddingTop: "41px",
      },
    },
    box_image: {
      "& ": {
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "10px",
        marginBottom: "10px",
        marginTop: "15px",
      },
    },
  })
);

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function ViewDetailTransaction({ isOpen, onClose }: Props) {
  const refresh = useRefresh();
  const record = useRecordContext();
  const [loading, setLoading] = React.useState(false);
  const [listProject, setListProject] = useState<any[]>([]);
  const [projectErr, setProjectErr] = React.useState<ErrorTypes | null>();
  const [projectId, setProjectId] = useState<any>();
  const [revenue, setRevenue] = useState();
  const [sale, setSale] = useState<any>();
  const [source, setSource] = useState<any>();
  const [feedbackCus, setFeedbackCus] = useState<any>();
  const [feedbackBuild, setFeedbackBuild] = useState<any>();
  const [time, setTime] = useState<any>();

  useEffect(() => {
    if (isOpen) {
      fnGetProjectByCus();
      fnGetDetail();
    }
  }, [isOpen]);

  const fnGetProjectByCus = async () => {
    await HttpDataService.findById(
      `customer/showProjetcByCustormer`,
      record.customer_id as string
    )
      .then((response: any) => {
        if (response.data.data) {
          const data = response.data.data ?? [];
          const mappedProjects = data.map((item: any) => ({
            id: item.project_id,
            name: item.name,
          }));

          setListProject(mappedProjects);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const fnGetDetail = async () => {
    await HttpDataService.findById(
      `transaction/get-transaction`,
      record.id as string
    )
      .then((response: any) => {
        const detail = response.data.data;
        const selectedProject = listProject.find(
          (project) => project.id === detail.project_estate_id
        );
        console.log(selectedProject);
        if (selectedProject) {
          setProjectId(selectedProject.id);
        }
        setRevenue(detail.revenue);
        setSource(detail.source);
        setSale(detail.sale);
        setFeedbackCus(detail.feedback_customer);
        setFeedbackBuild(detail.feedback_building);
        setTime(detail.time);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const RowActions = ({ record }: any) => {
    return (
      <Box className={cx("planRowAction")}>
        <AddImg />
        {/* {commonFunction.checkPermission("TRANSACTION_DELETE") && */}
      </Box>
    );
  };
  const handleAddImg = async () => {
    refresh();
  };
  const AddImg = (props: any) => {
    const [openEditDialogAddImg, setOpenEditDialogAddImg] = useState(false);
    return (
      <>
        <Tooltip title="Thêm hình ảnh hợp đồng" arrow>
          <IconButton onClick={() => setOpenEditDialogAddImg(true)}>
            {/* <AddIcon className={"btn_replay"} /> */}
          </IconButton>
        </Tooltip>
        <AddImgTransaction
          isOpenAdd={openEditDialogAddImg}
          onCloseAdd={() => setOpenEditDialogAddImg(false)}
        ></AddImgTransaction>
      </>
    );
  };
  const AddImgTransaction = ({ isOpenAdd, onCloseAdd }: any) => {
    const [imageCustomerProject, setImageCustomerProject] = React.useState<
      any[]
    >([]);
    const removeImageUtilities = (imgIndex: number) => {
      const newListImage = imageCustomerProject.filter(
        (_, index) => index !== imgIndex
      );
      if (imageCustomerProject.length === 1) {
        setImageCustomerProject([]);
      }
      setImageCustomerProject(newListImage);
    };
    return (
      <>
        <DialogFormCustomer
          loading={loading}
          minHeight="auto"
          title="Hình ảnh hợp đồng"
          isOpen={isOpenAdd}
          onClose={onCloseAdd}
          onSubmit={handleAddImg}
        >
          <div className="">
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              multiple
              onChange={(e: any) => {
                setImageCustomerProject([
                  ...imageCustomerProject,
                  ...e.target.files,
                ]);
                // e.target.value = null;
              }}
            ></input>
            {imageCustomerProject.length > 0 && (
              <div className={"image-show-wrapper"}>
                {imageCustomerProject.map((image: any, index: number) => (
                  <ImagePreview
                    key={index}
                    src={URL.createObjectURL(image)}
                    onRemove={() => removeImageUtilities(index)}
                  />
                ))}
              </div>
            )}
          </div>
        </DialogFormCustomer>
      </>
    );
  };
  const Change = () => {};
  return (
    <DialogFormCustomer
      loading={loading}
      minHeight="auto"
      title="Cập nhật giao dịch"
      size="md"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Wrapper>
        <TitleWrapper>
          <Autocomplete
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={<InputLabel title="Dự án" required />}
                value={projectId}
                error={projectErr?.isError}
                helperText={projectErr?.message}
              />
            )}
            sx={{ width: "100%" }}
            onChange={(event, value) => {
              setProjectId(value.id);
              setProjectErr(null);
            }}
            options={listProject}
            getOptionLabel={(option) => option?.name}
          />
        </TitleWrapper>
        <InputRowWrapper>
          <ItemWrapper>
            <TextField
              fullWidth
              multiline
              label={<InputLabel title="Người bán" required />}
              value={record?.user_sale}
              variant="outlined"
              inputProps={{ readOnly: true }}
            />
          </ItemWrapper>
          <ItemWrapper>
            <TextField
              fullWidth
              multiline
              label={<InputLabel title="Khách hàng" required />}
              value={record?.customer_name}
              variant="outlined"
              inputProps={{ readOnly: true }}
            />
          </ItemWrapper>
        </InputRowWrapper>

        <InputRowWrapper>
          <ItemWrapper>
            <PriceInput
              title="Doanh thu"
              val={revenue}
              required={false}
              onChange={(value) => {
                setRevenue(value);
              }}
            />
          </ItemWrapper>
          <ItemWrapper>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="vi">
              <DatePicker
                label={<InputLabel title="Thời gian giao dịch" required />}
                inputFormat="DD/MM/YYYY"
                value={time || null}
                onChange={(e) => setTime(e?.format("YYYY-MM-DD"))}
                renderInput={(params) => (
                  <TextField
                    className="mb-23"
                    {...params}
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </LocalizationProvider>
          </ItemWrapper>
        </InputRowWrapper>
        <InputRowWrapper>
          <ItemWrapper>
            <PriceInput
              title="Doanh số nguồn"
              val={source || 0}
              required={false}
              onChange={(value) => {
                setSource(value);
              }}
            />
          </ItemWrapper>
          <ItemWrapper>
            <PriceInput
              title="Doanh số sale"
              val={sale || 0}
              required={false}
              onChange={(value) => {
                setSale(value);
              }}
            />
          </ItemWrapper>
        </InputRowWrapper>
        <InputRowWrapper>
          <ItemWrapper>
            <TextField
              fullWidth
              multiline
              label={<InputLabel title="Feedback KH" />}
              value={feedbackCus}
              variant="outlined"
              onChange={(e) => setFeedbackCus(e.target.value)}
            />
          </ItemWrapper>
          <ItemWrapper>
            <TextField
              fullWidth
              multiline
              label={<InputLabel title="Feedback tòa nhà" />}
              value={feedbackBuild}
              variant="outlined"
              onChange={(e) => setFeedbackBuild(e.target.value)}
            />
          </ItemWrapper>
        </InputRowWrapper>
        <InputRowWrapper>
          <ItemWrapper style={{ width: "50%" }}>
            <div className="title-contract">
              <div className={"title-image"}>Hợp đồng</div>
              <RowActions label={"THAO TÁC"} record={record} />
            </div>

            {record?.image?.length > 0 &&
              record.image.map((item: any) => (
                <div>
                  <a
                    href={`${process.env.REACT_APP_BASE_END_POINT_URL}${item}`}
                    target="_blank"
                  >
                    <ImageListItem key={item.img}>
                      <img
                        width={"100%"}
                        src={`${process.env.REACT_APP_BASE_END_POINT_URL}${item}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  </a>
                </div>
              ))}
          </ItemWrapper>
          <ItemWrapper>
            <div className={"title-image"}>Ghi chú</div>
            {record?.note && (
              <Editor
                submitLoading
                data={record?.note || null}
                onChange={(event: any, editor: any) => {}}
              />
            )}
          </ItemWrapper>
        </InputRowWrapper>
      </Wrapper>
    </DialogFormCustomer>
  );
}
export default React.memo(ViewDetailTransaction);
